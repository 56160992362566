const messages = {
  license: "Lizenz",
  license_breadcrumb_title: "Lizenzen",
  license_title: "Lizenzen",
  license_information: "Lizenzinformationen",
  license_number: "Lizenznummer",
  license_number_devices: "Anzahl der Geräte",
  license_start_date: "Startdatum",
  license_end_date: "Enddatum",
  tenant: "Mieter",
  current_user_usage: "Aktuelle Benutzerverwendung",
  license_number_users: "Anzahl der Benutzer",
  license_current_site_usage: "Aktuelle Site-Nutzung",
  license_number_sites: "Anzahl der Sites",
  license_number_users_placeholder: "Geben Sie die Anzahl der Benutzer ein",
  license_number_sites_placeholder: "Geben Sie die Anzahl der Sites ein",
  license_number_devices_placeholder: "Geben Sie die Anzahl der Geräte ein",
  license_current_device_usage: "Aktuelle Geräteverwendung",
  tenant_export: "Exportieren",
  license_export: "Exportieren",
  tenant_add_btn: "Mieter hinzufügen",
  license_add_btn: "Lizenz hinzufügen",
  license_list: "Lizenzliste",
  lincense_number: "Lizenznummer",
  license_tenant: "Mieter",
  license_status: "Status",
  license_active: "Aktiv",
  license_deactivated: "Deaktiviert",
  license_expiry_start: "Ablaufstart",
  license_expiry_end: "Ablaufende",
  license_start: "Startdatum",
  license_end: "Enddatum",
  license_add: "Lizenz hinzufügen",
  license_date_pick: "Datumswahl",
  date_pick_validation_msg: "Datumswahl ist erforderlich.",
  license_users_validation_msg: "Anzahl der Benutzer ist erforderlich.",
  license_sites_validation_msg: "Anzahl der Sites ist erforderlich.",
  license_devices_validation_msg: "Anzahl der Geräte ist erforderlich.",
  tenant_validation_msg: "Mieter ist erforderlich.",
  tenant_select: "Mieter auswählen",
  license_new_success: "Lizenz erfolgreich hinzugefügt.",
  license_update: "Aktualisieren",
  license_update_title: "Lizenz aktualisieren",
  license_delete: "Löschen",
  delete_license: "Lizenz löschen",
  delete_license_text: "Möchten Sie wirklich löschen?",
  license_deleted: "Lizenz erfolgreich gelöscht.",
  tenant_admin: "Administrator",
  tenant_manage_email: "Support-E-Mail verwalten",
  tenant_id: "Mieter-ID",
  tenant_name: "Mietername",
  tenant_support_email_list: "Support-E-Mail-Liste",
  tenant_upload_license: "Lizenz hochladen",
  tenant_info: "Mieterinformation",
  tenant_license_info: "Lizenzinformationen",
  tenant_update_emails_success: "Support-E-Mails erfolgreich aktualisiert.",
  license_cabinets: "Schränke",
  license_dcim_controllers: "DCIM-Controller",
  tenant_email_valid: "Die Eingabe ist keine gültige E-Mail",
  tenant_enter_email: "Support-E-Mail-Adressen eingeben",
  tenant_email_exist: "Bereits vorhanden",
  tenant_email_enter: "E-Mail eingeben",
  support_email_list: "Support-E-Mail-Liste",
};

export default messages;
