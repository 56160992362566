import {
  Body16MediumPrimary,
  Title18MediumPrimary,
  Button16Invert,
} from "../../commonStyles";
import { ErrorPageContainer, ErrorPageFooter } from "./styled";

const ResouceNotFound = () => {
  return (
    <>
      <ErrorPageContainer>
        <div className="circle1">-</div>
        <div className="circle2">-</div>
        <div className="circle3">-</div>
        <div className="circle4">-</div>
        <div className="error-content">
          <Body16MediumPrimary>404</Body16MediumPrimary>
          <Body16MediumPrimary style={{ fontSize: 32 }}>
            Not Found
          </Body16MediumPrimary>
          <Title18MediumPrimary>
            Something unexpected happened. Please check with your administrator.
          </Title18MediumPrimary>
          <form action="/home" method="GET">
            <Button16Invert htmlType="submit" type="primary">
              Back to home
            </Button16Invert>
          </form>
        </div>
      </ErrorPageContainer>
      <ErrorPageFooter>
        <span>
          @2024 Datwyler.
          <a href="/home">Terms & Conditions</a>|<a href="/home">FAQ</a>
          version 1.0.0
        </span>
      </ErrorPageFooter>
    </>
  );
};

export default ResouceNotFound;
