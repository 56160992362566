import { useContext } from "react";
import { ConfigProvider, Table, TableProps } from "antd";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import styled from "styled-components";

interface StyledTableProps extends TableProps {
  headerPadding: string;
}

const StyledTable = styled(Table)<StyledTableProps>`
  .ant-table-title {
    padding: ${(props) => props.headerPadding || "16px"};
  }
  .ant-table-thead {
    .ant-table-cell {
      font-weight: 600;
      line-height: 20px;
    }
    & th::before {
      display: none;
    }
  }
`;

const CustomTable = ({ headerPadding, ...props }: StyledTableProps) => {
  const { theme } = useContext<any>(DarkModeContext);
  return (
    <ConfigProvider theme={theme}>
      <StyledTable headerPadding={headerPadding} {...props} />
    </ConfigProvider>
  );
};

export default CustomTable;
