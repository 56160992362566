import common_messages from "./common/fr_FR";
// import error_messages from "./error/fr_FR";
import navbar_messages from "./navbar/fr_FR";
import home_messages from "./home/fr_FR";
import alarm_messages from "./alarms/fr_FR";
// import sites_messages from "./sites/fr_FR";
import device_messages from "./device/fr_FR";
import user_messages from "./user/fr_FR";
import tenant_messages from "./tenant/fr_FR";
import telemetry_messages from "./telemetry/fr_FR";
// import gateway_messages from "./gateway/fr_FR";
import location_messages from "./location/fr_FR";
import keys from "./keys/fr_FR";

const messages = {
  ...common_messages,
  // ...error_messages,
  ...navbar_messages,
  ...home_messages,
  ...alarm_messages,
  // ...sites_messages,
  ...device_messages,
  ...user_messages,
  ...tenant_messages,
  ...telemetry_messages,
  // ...gateway_messages,
  ...location_messages,
  ...keys,
};

export default messages;
