import { useContext } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Dropdown, Space, Spin, ConfigProvider } from "antd";
import { DownSingleArrowIcon } from "../../icons";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import "../../css/dropdown.css";

// Dropdown are for one time changes only, eg. for navigation
// if using form, use Select instead.
const BasicDropdown = (props) => {
  const {
    onClick,
    items,
    menuStyle = {},
    labelStyle,
    isSpinning,
    label,
  } = props;

  const { theme } = useContext<any>(DarkModeContext);

  return (
    <ConfigProvider theme={theme}>
      <Dropdown
        menu={{
          onClick: onClick,
          items: items,
          style: { padding: 0, ...menuStyle },
        }}
      >
        <Space style={{ cursor: "pointer", ...labelStyle }}>
          {label}
          {isSpinning ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: "#12141C" }}
                  spin
                />
              }
            />
          ) : (
            <DownSingleArrowIcon
              width={"20px"}
              height={"20px"}
              style={{ position: "relative", top: "4px" }}
            />
          )}
        </Space>
      </Dropdown>
    </ConfigProvider>
  );
};

export default BasicDropdown;
