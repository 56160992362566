const messages = {
  attachment: "附件",
  attachments: "附件",
  upload_btn: "上传",
  cancel_btn: "取消",
  reset_btn: "重置",
  apply_btn: "应用",
  delete_btn: "删除",
  location: "位置",
  coordinates: "坐标",
  format: "格式",
  date: "日期",
  site: "站点",
  details: "细节",
  download: "下载",
  status: "状态",
  alert: "警报",
  delete: "删除",
  new_btn: "创建新",
  delete_location_btn: "删除位置",
  save_changes: "保存更改",
  home: "主页",
  site_placeholder: "选择站点",
  site_validation_msg: "请选择站点。",
  name: "名称",
  name_placeholder: "输入名称",
  name_validation_msg: "请输入名称。",
  location_label: "位置名称",
  location_placeholder: "输入位置名称",
  location_validation_msg: "请输入位置名称。",
  country: "国家",
  country_placeholder: "输入国家",
  country_validation_msg: "请选择国家。",
  longitude: "经度",
  longitude_placeholder: "输入经度",
  longitude_validation_msg: "请选择经度。",
  latitude: "纬度",
  latitude_placeholder: "输入纬度",
  latitude_validation_msg: "请选择纬度。",
  description: "描述",
  description_placeholder: "输入描述",
  description_validation_msg: "请输入描述。",
  add_site_label: "站点名称",
  add_site_placeholder: "输入站点名称",
  add_site_validation_msg: "请输入站点。",
  url: "网址",
  url_placeholder: "输入网址",
  url_validation_msg: "请输入网址。",
  file_placeholder: "选择文件格式",
  attachment_upload_msg: "附件上传成功",
  attachment_upload_error: "附件上传失败。",
  drag_drop_label: "拖拽上传或",
  choose_file: "选择文件",
  maximun_file_size: "最大文件大小 100MB",
  invalid_file_format: "文件格式不支持。",
  file_size_validation: "文件必须小于 100MB！",
  file_required: "请上传文件！",
  delete_attachment_title: "删除附件",
  delete_attachment_text: "您确定要删除吗？",
  delete_location_text: "您确定要删除此位置吗？此操作无法撤销。",
  information_modal_title: "附件信息",
  location_delete_validation: "无法删除。此位置存在一个或多个活动站点。",
  add_location: "添加位置",
  add_location_msg: "位置添加成功",
  update_location_msg: "位置更新成功",
  del_site_validation_msg: "无法删除。此站点存在一个或多个设备。",
  add_site: "添加新站点",
  attachment_deleted_success: "附件删除成功",
  location_add: "添加",
};

export default messages;
