const messages = {
  // Languages
  "en-GB": "English",
  "zh-CN": "Chinese (中文)",
  "fr-FR": "French (française)",
  "de-CH": "German (Deutsch)",

  // menu
  menu_home: "Home",
  menu_site: "Locations",
  menu_device_monitoring: "Devices",
  menu_device: "Devices",
  menu_alarm: "Alarms",
  menu_user: "Users",
  menu_profile: "Profile",
  menu_tenant: "Tenants",
  menu_license: "Licenses",
  menu_telemetry: "Telemetries",
  menu_attachments: "Attachments",
  menu_gateway: "Gateway",
  menu_analytics: "Analytics",
  menu_admin: "Admin",
  menu_site_attachments: "Attachments",
  menu_user_profile: "Profile",
  select_tenant: "Tenant",

  license_expired_banner: "Your license is expired",
  license_expiring_banner: "Your license is going to expire on",
};

export default messages;
