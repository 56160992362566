import { CustomThemeConfigProps } from "./types";

const colorPalette = {
  datwylerBrand: {
    primary1: "#F1F7F8",
    primary2: "#EBFDFF",
    primary3: "#46BBCB",
    primary4: "#0C8595",
    primary5: "#05515B",
  },
  datwylerText: {
    textBrand: "#0C8595",
    textPrimary: "#12141C",
    textSecondary: "#5A5C5F",
    textTertiary: "#8F9399",
    textSuccess: "#0DB328",
    textError: "#F82834",
    textInvert: "#FFFFFF",
    textWarning: "#EE880F",
  },
  datwylerBorder: {
    borderBrand: "#46BBCB",
    borderPrimary: "#E1E1E4",
    borderSecondary: "#8F9399",
    borderSuccess: "#0DB328",
    borderError: "#F82834",
  },
  datwylerIcon: {
    iconBrand: "#0C8595",
    iconPrimary: "#12141C",
    iconSecondary: "#5A5C5F",
    iconTertiary: "#8F9399",
    iconSuccess: "#0DB328",
    iconError: "#F82834",
    iconInvert: "#FFFFFF",
    iconWarning: "#EE880F",
    iconInfo: "#4185f4",
  },
  datwylerSurface: {
    surfaceBrand1: "#F1F7F8",
    surfaceBrand2: "#0C8595",
    surfaceBrand3: "#46BBCB",
    SurfacePrimary: "#FFFFFF",
    surfaceSecondary: "#EFF2F5",
    surfaceTertiary: "#E2E2E2",
    surfaceScreen: "#F8FAFB",
    surfaceOrange1: "#EE880F",
    surfaceOrange2: "#FFF7EE",
    surfaceError1: "#F82834",
    surfaceError2: "#FFEEF3",
    surfaceSuccess: "#0DB328",
    surfaceSuccess2: "#EEFFF1",
  },
};

const theme: CustomThemeConfigProps = {
  token: {
    colorPrimary: colorPalette.datwylerBrand.primary4,
    fontFamily: "Poppins",
    borderRadius: 4,
    ...colorPalette,
  },
  components: {
    Table: {
      headerBg: colorPalette.datwylerSurface.surfaceBrand1,
      footerBg: colorPalette.datwylerSurface.surfaceBrand1,
      headerColor: colorPalette.datwylerText.textSecondary,
      colorBgContainer: colorPalette.datwylerSurface.SurfacePrimary,
      headerBorderRadius: 4,
    },
    Layout: {
      headerBg: "#FFFFFF",
      fontFamily: "Poppins",
    },
    Menu: {
      itemColor: "#8F9399",
      itemSelectedBg: "#0C8595",
      itemSelectedColor: "#FFFFFF",
      itemHoverBg: "#F8FAFB",
      fontFamily: "Poppins",
    },
    Dropdown: {
      controlItemBgHover: "#F8FAFB",
      colorText: colorPalette.datwylerText.textPrimary,
    },
    Typography: {
      fontFamily: "Poppins",
      titleMarginBottom: 0,
      fontSizeHeading2: 28,
      fontFamilyCode: "Poppins",
      fontWeightStrong: 500,
      colorText: colorPalette.datwylerText.textPrimary,
      colorTextHeading: colorPalette.datwylerText.textPrimary,
    },
    Button: {
      fontFamily: "Poppins",
      colorText: colorPalette.datwylerText.textPrimary,
      defaultBorderColor: colorPalette.datwylerBorder.borderPrimary,
      defaultHoverBorderColor: "#0C8595",
      defaultBg: "unset",
      contentFontSize: 14,
      fontWeight: 600,
      primaryShadow: "",
      defaultShadow: "",
      dangerShadow: "",
      textHoverBg: "transparent",
    },
    Switch: {
      colorPrimaryHover: "#0c8595b3",
      colorPrimary: "#0C8595",
      colorTextQuaternary: "#EFF2F5",
    },
    Card: {
      colorBgContainer: colorPalette.datwylerSurface.SurfacePrimary,
      colorBorderSecondary: colorPalette.datwylerBorder.borderPrimary,
      colorText: colorPalette.datwylerText.textTertiary,
      borderRadiusLG: 4,
    },
    Modal: {
      contentBg: colorPalette.datwylerSurface.SurfacePrimary,
      headerBg: colorPalette.datwylerSurface.SurfacePrimary,
      borderRadiusLG: 4,
    },
    Checkbox: {
      colorText: colorPalette.datwylerText.textPrimary,
    },
    Input: {
      colorText: colorPalette.datwylerText.textPrimary,
      colorBgContainer: colorPalette.datwylerSurface.surfaceSecondary,
      paddingBlock: 16,
      paddingInline: 16,
      paddingBlockLG: 16,
      paddingInlineLG: 16,
    },
    Form: {
      labelColor: colorPalette.datwylerText.textPrimary,
    },
    Select: {
      // colorText: colorPalette.datwylerText.textPrimary,
      // colorTextPlaceholder: colorPalette.datwylerText.textTertiary,
      // selectorBg: "#26db12",
      // colorBgElevated: colorPalette.datwylerSurface.surfaceScreen,
      // optionActiveBg: colorPalette.datwylerSurface.surfaceScreen,
      colorBgContainer: colorPalette.datwylerSurface.surfaceSecondary,
      optionSelectedBg: colorPalette.datwylerSurface.surfaceScreen,
      optionSelectedColor: colorPalette.datwylerText.textBrand,
      colorBorderSecondary: colorPalette.datwylerBorder.borderPrimary,
      optionSelectedFontWeight: 400,
      optionFontSize: 14,
      optionPadding: 16,
      fontSizeLG: 16,
      borderRadiusLG: 4,
      borderRadius: 4,
      borderRadiusSM: 0,
      paddingXXS: 0,
      fontSize: 16,
      boxShadowSecondary: `0 0 0 1px ${colorPalette.datwylerBorder.borderPrimary}`,
    },
    Notification: {
      colorBgElevated: colorPalette.datwylerSurface.surfaceSecondary,
      colorText: colorPalette.datwylerText.textPrimary,
      colorTextHeading: colorPalette.datwylerText.textPrimary,
      borderRadiusLG: 4,
    },
    Breadcrumb: {
      fontFamily: "Poppins",
      colorText: colorPalette.datwylerText.textPrimary,
      itemColor: colorPalette.datwylerText.textTertiary,
      linkColor: colorPalette.datwylerText.textTertiary,
      fontSize: 14,
      fontWeightStrong: 600,
    },
    Pagination: {
      itemActiveBg: colorPalette.datwylerSurface.surfaceBrand2,
      colorPrimary: colorPalette.datwylerText.textInvert,
      colorText: colorPalette.datwylerText.textTertiary,
      lineWidth: 0,
      colorPrimaryHover: colorPalette.datwylerBrand.primary3,
    },
    DatePicker: {
      colorBgContainer: colorPalette.datwylerSurface.surfaceSecondary,
    },
  },
};

export default theme;
