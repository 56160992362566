const messages = {
  license: "License",
  license_breadcrumb_title: "Licenses",
  license_title: "Licenses",
  license_information: "License information",
  license_number: "License Number",
  license_number_devices: "Number of Devices",
  license_start_date: "Start Date",
  license_end_date: "End Date",
  tenant: "Tenant",
  current_user_usage: "Current User Usage",
  license_number_users: "Number of Users",
  license_current_site_usage: "Current Site Usage",
  license_number_sites: "Number of Sites",
  license_number_users_placeholder: "Enter number of users",
  license_number_sites_placeholder: "Enter number of sites",
  license_number_devices_placeholder: "Enter number of devices",
  license_current_device_usage: "Current Device Usage",
  tenant_export: "Export",
  license_export: "Export",
  tenant_add_btn: "Add Tenant",
  license_add_btn: "Add License",
  license_list: "License List",
  lincense_number: "Lincense Number",
  license_tenant: "Tenant",
  license_status: "Status",
  license_active: "Active",
  license_deactivated: "Deactivated",
  license_expiry_start: "Expiry Start",
  license_expiry_end: "Expiry End",
  license_start: "Start Date",
  license_end: "End Date",
  license_add: "Add License",
  license_date_pick: "Date Pick",
  date_pick_validation_msg: " Date pick is required.",
  license_users_validation_msg: "Number of users is required.",
  license_sites_validation_msg: "Number of sites is required.",
  license_devices_validation_msg: "Number of devices is required.",
  tenant_validation_msg: "Tenant is required.",
  tenant_select: "Select Tenant",
  license_new_success: "License added successfully.",
  license_update: "Update",
  license_update_title: "Update License",
  license_delete: "Delete",
  delete_license: "Delete License",
  delete_license_text: "Are you sure you want to delete?",
  license_deleted: "License deleted successfully.",
  tenant_admin: "Admin",
  tenant_manage_email: "Manage Support Email",
  tenant_id: "Tenant ID",
  tenant_name: "Tenant Name",
  tenant_support_email_list: "Support Email List",
  tenant_upload_license: "Upload License",
  tenant_info: "Tenant Information",
  tenant_license_info: "License Information",
  tenant_update_emails_success: "Support emails updated successfully.",
  license_cabinets: "Cabinets",
  license_dcim_controllers: "DCIM Controllers",
  tenant_email_valid: "The input is not valid E-mail",
  tenant_enter_email: " Enter Support Email Addresses",
  tenant_email_exist: "Already Exist",
  tenant_email_enter: "Enter Email",
  support_email_list: "Support Email List",

  // Upload Certificate
  certificate_metadata: "Certificate Metadata",
  tenant_upload_certificate: "Upload Certificate",
  checksum: "Checksum",
  created_by: "Created By",
  created_on: "Created On"
};

export default messages;
