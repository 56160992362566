const messages = {
  parameter: "Paramètre",
  select_date: "Sélectionnez une date",
  timestamp: "Horodatage",
  no_filters: "Veuillez saisir des filtres",
  no_device: "Aucun appareil dans le(s) site(s) sélectionné(s)",
  no_data: "Aucune donnée",

  // excel
  excel_filename_telemetry: "Rapport de télémétrie",
};

export default messages;
