const messages = {
  device_power_status: "状态",
  device_on: "在线",
  device_off: "离线",
  sim_card: "SIM卡",
  sim_inserted: "已插入",
  sim_not_inserted: "未插入",
  last_connection: "最后连接",
  firmware_version: "固件版本",
  device_information: "设备信息",
  connection: "连接",
  device_type: "设备类型",
  serial_number: "序列号",
  modem_imei: "调制解调器IMEI",
  modem_operator: "调制解调器运营商",
  signal_strength: "信号强度",
  signal_strength_0: "无信号",
  signal_strength_1: "差",
  signal_strength_2: "一般",
  signal_strength_3: "好",
  signal_strength_4: "优秀",
  conn_type: "连接类型",
  conn_state: "连接状态",
  connected: "已连接",
  disconnected: "已断开",
  conn_uptime: "连接时长",

  modem_temperature: "温度",
  modem_signal: "信号",
  temperature: "温度",

  modem_sent_today: "今日调制解调器发送",
  cpu_percent: "CPU百分比",
  cpu_loadavg_1min: "CPU平均负载1分钟",
  cpu_loadavg_5min: "CPU平均负载5分钟",
  modem_received_today: "今日调制解调器接收",
  memory_info_percent: "内存信息百分比",
  memory_info_free: "内存空闲",
  disk_usage_percent: "磁盘使用百分比",
  disk_usage_free: "磁盘空闲",
  memory_info_total: "内存总量",
  cpu_loadavg_15min: "CPU平均负载15分钟",
  disk_usage_total: "磁盘总使用",

  water_leakage: "水泄漏",
  front_door: "前门",
  battery_temperature: "电池温度",

  network_packets_recv: "接收的网络数据包",
  network_packets_sent: "发送的网络数据包",
  gateway_last_seen: "网关最后一次见",
  disk_used_percent: "已用磁盘百分比",
  network_bytes_recv: "接收的网络字节",
  memory_used_percent: "已用内存百分比",
  loadavg_1: "平均负载1分钟",
  network_bytes_sent: "发送的网络字节",
  loadavg_15: "平均负载15分钟",
  loadavg_5: "平均负载5分钟",
  core_temperature: "核心温度",
  network_counter: "网络计数器",
  disk_total: "磁盘总量",
  memory_used: "已用内存",
  disk_used: "已用磁盘",
  disk_free: "磁盘空闲",
  memory_total: "内存总量",
  memory_free: "内存空闲",
  cpu: "CPU",
  uptime: "正常运行时间",
};

export default messages;
