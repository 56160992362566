import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const MAIN_METER_READING = gql`
  query mainMeterReading($tenantId: ID!, $siteId: ID) {
    mainMeterReading(tenantId: $tenantId, siteId: $siteId) {
      value
      unit
    }
  }
`;

const IT_METER_READING = gql`
  query itMeterReading($tenantId: ID!, $siteId: ID) {
    itMeterReading(tenantId: $tenantId, siteId: $siteId) {
      value
      unit
    }
  }
`;

const PUE_METER_READING = gql`
  query pueReading($tenantId: ID!, $siteId: ID) {
    pueReading(tenantId: $tenantId, siteId: $siteId) {
      value
    }
  }
`;

const useWidgets = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);

  const [
    fetchMainMeter,
    {
      loading: isFetchMainMeterLoading,
      error: fetchMainMeterError,
      data: fetchMainMeterData,
    },
  ] = useLazyQuery(MAIN_METER_READING, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    fetchITMeter,
    {
      loading: isFetchITMeterLoading,
      error: fetchITMeterError,
      data: fetchITMeterData,
    },
  ] = useLazyQuery(IT_METER_READING, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    fetchPUEMeter,
    {
      loading: isFetchPUEMeterLoading,
      error: fetchPUEMeterError,
      data: fetchPUEMeterData,
    },
  ] = useLazyQuery(PUE_METER_READING, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (fetchMainMeterError || fetchITMeterError || fetchPUEMeterError) {
      const error =
        fetchMainMeterError || fetchITMeterError || fetchPUEMeterError;
      handleError(error, uri, openNotification, intl);
    }
  }, [
    fetchMainMeterError,
    fetchITMeterError,
    fetchPUEMeterError,
    intl,
    openNotification,
    uri,
  ]);

  return {
    fetchMainMeter,
    isFetchMainMeterLoading,
    fetchMainMeterError,
    fetchMainMeterData,
    fetchITMeter,
    isFetchITMeterLoading,
    fetchITMeterError,
    fetchITMeterData,
    fetchPUEMeter,
    isFetchPUEMeterLoading,
    fetchPUEMeterError,
    fetchPUEMeterData,
  };
};

export default useWidgets;
