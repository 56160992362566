const messages = {
  // Common headers
  id: "ID",
  status: "l' état",

  // // Device
  model: "Modèle",
  add_device: "Ajouter un appareil",
  device_name: "Nom de l'appareil",
  devices: "Appareils",
  // description: "Description",

  // // Location
  // location: "Emplacement",
  // site: "Site",

  // Buttons
  // edit: "Modifier",
  // delete: "Supprimer",
  save: "Enregistrer",
  // update: "mettre à jour",
  cancel: "Annuler",
  export: "Télécharger",
  filter: "Filtre",
  apply: "Appliquer",
  reset: "Réinitialiser",
  save_changes: "Enregistrer les modifications",

  // // Common headers
  // settings: "Paramètres",
  // detail: "Détail",
  // details: "Détails",
  // status: "l' état",
  // alert: "Alerte",
  // id: "ID",
  // actions: "Actions",

  // // Table Headers
  // last_updated_by: "Dernière mise à jour par",
  // last_updated_time: "Heure de la dernière mise à jour",

  // // Confirm messages
  // confirm_delete: "Etes-vous sûr que vous voulez supprimer?",
  confirm_deactivate: "Êtes-vous sûr de vouloir désactiver ?",

  // Success messages
  success_del: "Supprimé avec succès",
  add_device_success: "Ajout réussi d'un appareil",
  device_updated: "Appareil mis à jour",
  command_sent_with_delay:
    "Commande envoyée. Les paramètres peuvent mettre un certain temps à être pris en compte.",
  upload_success: "Télécharger le succès",
  // device_restart_success: "La commande de redémarrage a été envoyée",
  // update_setting_success:
  //   "Vos paramètres ont été mis à jour avec succès. Les paramètres peuvent mettre un certain temps à être pris en compte.",
  // login_success: "Connexion réussie",
  // update_user_password_success: "Votre mot de passe a été modifié avec succès.",

  // User Input
  all: "Tout",
  // none: "Aucun",
  // select_site: "Sélectionnez un site",
  // select_period: "Sélectionnez la période",
  sort_by: "Trier par",
  required_msg: "Ce champ est obligatoire.",

  // Text input
  max_char: "Caractères maximum",

  // Input Placeholders
  enter_first_name: "Entrez le prénom",
  enter_last_name: "Entrez le nom de famille",
  enter_email: "Entrez l'e-mail",
  select_role: "Sélectionnez un rôle",
  enter_company: "Entrez l'entreprise",
  enter_phone_number: "Entrez le numéro de téléphone",

  // // BreadCrumbs
  // Home: "Accueil",
  // Device: "Appareils",
  // Alarm: "Alarmes",
  // User: "Utilisateurs",
  // Tenants: "Locataires",
  // Telemetry: "Télémétries",

  // // Others
  // not_available: "Pas disponible",
  // date: "Date",
  // remove: "Retirer",
  // search: "Recherche",
  rows_per_page: "Lignes par page",

  // // Severities
  // information: "Information",
  // warning: "Avertissement",
  // critical: "Crucial",

  // Status
  ACTIVE: "Actif",
  DEACTIVATED: "Désactivé",
  // acknowledged: "Reconnu",
  // escalated: "escaladé",
  // in_progress: "en cours",
  // resolved: "Résolue",

  // TenantInfo
  tenant_information: "Informations sur le locataire",
  tenant_id: "ID du locataire",
  tenant_name: "Nom du locataire",

  // // Date Range input
  // date_range_label: "Date de début - Date de fin",

  // // Dates
  // start_date: "Date de début",
  // end_date: "Date de fin",

  // Dark Mode
  dark_mode: "Mode sombre",
  dark_mode_auto:
    "Automatique (suivre les paramètres du système d'exploitation)",

  //timeperiod
  hour: "Heure",
  day: "Jour",
  week: "Semaine",

  // //email input
  // support_email_addresses: "Entrez les adresses e-mail d'assistance",
  // enter_email: "Entrez l'e-mail",
  // manage_support_email_btn: "Gérer l'e-mail d'assistance",

  // Others
  loading: "Chargement",

  // errors
  error: "Error",
  err_set_params: "Erreur dans les données pour les paramètres définis",
};

export default messages;
