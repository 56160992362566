import { Card, Row, Col, Typography, ConfigProvider } from "antd";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { useContext } from "react";
import { useIntl } from "react-intl";
const { Text } = Typography;

const TenantInformation = (props) => {
  // common component used in Profile and Tenant Screen
  // extra is any component(eg. Manage Support Email button) to be added at the right side of the title
  const { tenantInfo, extra } = props;
  const { theme } = useContext<any>(DarkModeContext);
  const intl = useIntl();

  const textStyle = {
    fontSize: "16px",
    fontWeight: 400,
  };

  const keyTextStyle = {
    color: "#8F9399",
    fontSize: "16px",
    fontWeight: 400,
  };

  const titleStyle = {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
  };

  return (
    <ConfigProvider theme={theme}>
      <Card
        title={
          <Text style={titleStyle}>
            {intl.formatMessage({ id: "tenant_information" })}
          </Text>
        }
        extra={extra}
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Row gutter={[0, 24]}>
              <Col span={8}>
                <Text style={keyTextStyle}>
                  {intl.formatMessage({ id: "tenant_name" })}
                </Text>
              </Col>
              <Col span={16}>
                <Text style={textStyle}>{tenantInfo.name}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 24]}>
              <Col span={8}>
                <Text style={keyTextStyle}>
                  {intl.formatMessage({ id: "tenant_id" })}
                </Text>
              </Col>
              <Col span={16}>
                <Text style={textStyle}>{tenantInfo.id}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  );
};

export default TenantInformation;
