const messages = {
  home_widget_btn: "Widget hinzufügen",

  /** grid widgets */
  home_widget_online_devices: "Online-Geräte",
  home_widget_smoke_sensor: "Rauchmelder",
  home_widget_door_alarm: "Türalarm",
  home_widget_pdu_alert: "PDU-Alarm",
  home_widget_critical_alarm: "Kritischer Alarm",
  home_widget_water_leak: "Wasserleck",

  home_week_filter: "Woche",
  home_day_filter: "Tag",
  home_hour_filter: "Stunde",
  home_all_sites: "Alle Seiten",
  /** Widgets */
  home_widget_humidity: "Luftfeuchtigkeit",
  home_widget_temperature: "Temperatur",
  home_widget_legend_week: "Woche",
  home_widget_legend_day: "Tag",
  home_widget_legend_hour: "Stunde",
  home_widget_legend_period: "Vorherige Periode",
  home_widget_del_text:
    "Das Löschen von Widgets wirkt sich auf alle anderen Benutzer aus. Möchten Sie wirklich löschen?",
  home_widget_del_dialog: "Widget löschen",
  home_widget_del_btn: "Löschen",
  home_widget_cancel: "Stornieren",

  home_widget_main_meter: "Hauptmeter",
  home_widget_it_meter: "IT-Meter",
  home_widget_pue: "PUE",
  home_widget_temp_humidity: "Temperatur & Luftfeuchtigkeit",
  home_widget_ups_alarm: "USV-Gerätealarm",
  home_widget_cooling_system_alarm: "Kühlgerätealarm",

  home_widget_table_cooling: "Kühlgeräte",
  home_widget_table_ups: "USV-Geräte",
  home_widget_site_col: "Seiten",
  home_widget_device_col: "Gerät",
  home_widget_alert_col: "Alarm",
  home_widget_severity_col: "Schwere",
  home_widget_date_col: "Datum",

  home_map_title: "Geografische Karte",
};

export default messages;
