import { Layout } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  DEFAULTNAVBARWIDTH,
  HEADERHEIGHT,
  HEADERHEIGHTMOBILE,
} from "../../constants";
import useScreenOptions from "../../hooks/useScreenOptions";
import { DarkModeContext } from "../../providers/DarkModeProvider";

const { Content } = Layout;

const useNavBarWidthListener = (callback) => {
  window.addEventListener("navBarChanged", () => {
    callback();
  });
};

export const getNavBarWidth = () => {
  if (window.localStorage.getItem("navBarWidth")) {
    return parseInt(window.localStorage.getItem("navBarWidth"));
  } else return DEFAULTNAVBARWIDTH;
};

const ContentWrapper = (props) => {
  const { children } = props;
  const { isMobileScreen } = useScreenOptions();
  const { isDarkMode }: any = useContext(DarkModeContext);
  const defaultContentStyle = useMemo(
    () => ({
      zIndex: 1,
      marginLeft: getNavBarWidth(),
      backgroundColor: isDarkMode ? "#12141C" : "#f8f8fa",
      height: `calc(100vh - ${
        isMobileScreen ? HEADERHEIGHTMOBILE : HEADERHEIGHT
      }px)`,
      padding: isMobileScreen ? "32px 16px 32px 16px" : "16px 16px 16px 0px",
    }),
    [isDarkMode, isMobileScreen]
  );

  const [contentStyle, setContentStyle] = useState(defaultContentStyle);

  useEffect(() => {
    setContentStyle({
      ...defaultContentStyle,
      backgroundColor: isDarkMode ? "#12141C" : "#f8f8fa",
    });
  }, [isDarkMode, defaultContentStyle]);

  useNavBarWidthListener(() =>
    setContentStyle({
      ...defaultContentStyle,
      marginLeft: parseInt(window.localStorage.getItem("navBarWidth")),
    })
  );

  return (
    <Layout style={{ backgroundColor: isDarkMode ? "#12141C" : "#f8f8fa" }}>
      <Content style={contentStyle} id="contentWrapper">
        {children}
      </Content>
    </Layout>
  );
};

export default ContentWrapper;
