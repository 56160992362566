const messages = {
  attachment: "Pièce jointe",
  attachments: "Pièces jointes",
  upload_btn: "Télécharger",
  cancel_btn: "Annuler",
  reset_btn: "Réinitialiser",
  apply_btn: "Appliquer",
  delete_btn: "Supprimer",
  location: "Emplacement",
  coordinates: "Coordonnées",
  format: "Format",
  date: "Date",
  site: "Site",
  details: "Détails",
  download: "Télécharger",
  status: "Statut",
  alert: "Alerte",
  delete: "Supprimer",
  new_btn: "Créer Nouveau",
  delete_location_btn: "Supprimer l'Emplacement",
  save_changes: "Sauvegarder les Modifications",
  home: "Accueil",
  site_placeholder: "Sélectionner le Site",
  site_validation_msg: "Veuillez sélectionner un site.",
  name: "Nom",
  name_placeholder: "Entrez le Nom",
  name_validation_msg: "Veuillez entrer un nom.",
  location_label: "Nom de l'Emplacement",
  location_placeholder: "Entrez le Nom de l'Emplacement",
  location_validation_msg: "Veuillez entrer le nom de l'emplacement.",
  country: "Pays",
  country_placeholder: "Entrez le Pays",
  country_validation_msg: "Veuillez sélectionner un pays.",
  longitude: "Longitude",
  longitude_placeholder: "Entrez la Longitude",
  longitude_validation_msg: "Veuillez sélectionner la longitude.",
  latitude: "Latitude",
  latitude_placeholder: "Entrez la Latitude",
  latitude_validation_msg: "Veuillez sélectionner la latitude.",
  description: "Description",
  description_placeholder: "Entrez la Description",
  description_validation_msg: "Veuillez entrer une description.",
  add_site_label: "Nom du Site",
  add_site_placeholder: "Entrez le Nom du Site",
  add_site_validation_msg: "Veuillez entrer un site.",
  url: "URL",
  url_placeholder: "Entrez l'URL",
  url_validation_msg: "Veuillez entrer l'URL.",
  file_placeholder: "Sélectionnez le Format de Fichier",
  attachment_upload_msg: "Pièce jointe téléchargée avec succès",
  attachment_upload_error: "Échec du téléchargement de la pièce jointe.",
  drag_drop_label: "Glissez et déposez pour télécharger ou",
  choose_file: "Choisissez un Fichier",
  maximun_file_size: "Taille maximale du fichier 100 Mo",
  invalid_file_format: "Format de fichier non pris en charge.",
  file_size_validation: "Le fichier doit être plus petit que 100 Mo !",
  file_required: "Veuillez télécharger un fichier !",
  delete_attachment_title: "Supprimer la Pièce Jointe",
  delete_attachment_text: "Êtes-vous sûr de vouloir supprimer ?",
  delete_location_text:
    "Êtes-vous sûr de vouloir supprimer cet emplacement ? Cette action ne peut pas être annulée.",
  information_modal_title: "Informations sur la Pièce Jointe",
  location_delete_validation:
    "Impossible de supprimer. Un ou plusieurs sites actifs existent dans cet emplacement.",
  add_location: "Ajouter un Emplacement",
  add_location_msg: "Emplacement ajouté avec succès",
  update_location_msg: "Emplacement mis à jour avec succès",
  del_site_validation_msg:
    "Impossible de supprimer. Un ou plusieurs appareils existent dans ce site.",
  add_site: "Ajouter un Nouveau Site",
  attachment_deleted_success: "Pièce jointe supprimée avec succès",
  location_add: "ajouter",
};

export default messages;
