const messages = {
  license: "许可证",
  license_breadcrumb_title: "许可证",
  license_title: "许可证",
  license_information: "许可证信息",
  license_number: "许可证号",
  license_number_devices: "设备数量",
  license_start_date: "开始日期",
  license_end_date: "结束日期",
  tenant: "租户",
  current_user_usage: "当前用户使用情况",
  license_number_users: "用户数量",
  license_current_site_usage: "当前站点使用情况",
  license_number_sites: "站点数量",
  license_number_users_placeholder: "请输入用户数量",
  license_number_sites_placeholder: "请输入站点数量",
  license_number_devices_placeholder: "请输入设备数量",
  license_current_device_usage: "当前设备使用情况",
  tenant_export: "导出",
  license_export: "导出",
  tenant_add_btn: "添加租户",
  license_add_btn: "添加许可证",
  license_list: "许可证列表",
  lincense_number: "许可证号",
  license_tenant: "租户",
  license_status: "状态",
  license_active: "激活",
  license_deactivated: "已停用",
  license_expiry_start: "过期开始",
  license_expiry_end: "过期结束",
  license_start: "开始日期",
  license_end: "结束日期",
  license_add: "添加许可证",
  license_date_pick: "日期选择",
  date_pick_validation_msg: "日期选择是必需的。",
  license_users_validation_msg: "需要输入用户数量。",
  license_sites_validation_msg: "需要输入站点数量。",
  license_devices_validation_msg: "需要输入设备数量。",
  tenant_validation_msg: "租户是必需的。",
  tenant_select: "选择租户",
  license_new_success: "许可证添加成功。",
  license_update: "更新",
  license_update_title: "更新许可证",
  license_delete: "删除",
  delete_license: "删除许可证",
  delete_license_text: "您确定要删除吗？",
  license_deleted: "许可证已成功删除。",
  tenant_admin: "管理员",
  tenant_manage_email: "管理支持电子邮件",
  tenant_id: "租户ID",
  tenant_name: "租户名称",
  tenant_support_email_list: "支持电子邮件列表",
  tenant_upload_license: "上传许可证",
  tenant_info: "租户信息",
  tenant_license_info: "许可证信息",
  tenant_update_emails_success: "支持电子邮件更新成功。",
  license_cabinets: "柜子",
  license_dcim_controllers: "DCIM 控制器",
  tenant_email_valid: "输入的不是有效的电子邮件",
  tenant_enter_email: "请输入支持电子邮件地址",
  tenant_email_exist: "已经存在",
  tenant_email_enter: "输入电子邮件",
  support_email_list: "支持电子邮件列表",
};

export default messages;
