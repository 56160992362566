import { useState, createContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import lightTheme from "./light-theme";
import darkTheme from "./dark-theme";

export const useDarkModeListener = (callback) => {
  window.addEventListener("darkModeChanged", () => {
    callback();
  });
};

export const useDarkModeAutoListener = (callback) => {
  window.addEventListener("darkModeAutoChanged", () => {
    callback();
  });
};

const isOSDarkMode = () => {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

export const DarkModeContext = createContext({});

export const DarkModeProvider = (props: any) => {
  const existingDarkMode =
    window.localStorage?.getItem("darkMode") === "true" || false;
  const existingDarkModeAuto =
    window.localStorage?.getItem("darkModeAuto") === "true" || false;

  const { children } = props;
  const [isDarkMode, setIsDarkMode] = useState(existingDarkMode);
  const [isDarkModeAuto, setIsDarkModeAuto] = useState(existingDarkModeAuto);

  useEffect(() => {
    const onChangeOSDarkMode = (event) => {
      const newIsDarkModeAuto = event.matches ? "dark" : "light";
      const isDarkModeAuto =
        window.localStorage?.getItem("darkModeAuto") === "true";
      if (isDarkModeAuto) setIsDarkMode(newIsDarkModeAuto === "dark");
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => onChangeOSDarkMode(event));

    return () => {
      window.removeEventListener("scroll", onChangeOSDarkMode);
    };
  }, []);

  useDarkModeListener(() => {
    const isDarkMode = window.localStorage?.getItem("darkMode") === "true";
    setIsDarkMode(isDarkMode);
  });

  useDarkModeAutoListener(() => {
    const isDarkModeAuto =
      window.localStorage?.getItem("darkModeAuto") === "true";
    setIsDarkModeAuto(isDarkModeAuto);

    if (isDarkModeAuto) {
      window.localStorage.setItem("darkMode", isOSDarkMode().toString());
      window.dispatchEvent(new Event("darkModeChanged"));
    }
  });

  const theme = isDarkMode ? darkTheme : lightTheme;
  document.documentElement.className = isDarkMode ? "dark" : "light";
  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode: isDarkMode,
        isDarkModeAuto: isDarkModeAuto,
        theme: theme,
      }}
    >
      <ThemeProvider theme={theme.token}> {children}</ThemeProvider>
    </DarkModeContext.Provider>
  );
};
