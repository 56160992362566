const messages = {
  // Languages
  "en-GB": "English",
  "zh-CN": "Chinese (中文)",
  "fr-FR": "French (française)",
  "de-CH": "German (Deutsch)",

  // menu
  menu_home: "主页",
  menu_site: "地点",
  "menu_device-monitoring": "设备监控", // Remove this once New device MFE is ready
  menu_device: "设备监控",
  menu_alarm: "警报",
  menu_user: "账户管理",
  menu_profile: "账户资料",
  menu_tenant: "用户",
  menu_license: "许可证书",
  menu_telemetry: "遥测",
  menu_attachments: "附件",
  menu_gateway: "网关",
  menu_analytics: "分析",
  menu_admin: "行政",
  menu_site_attachments: "附件",
  menu_user_profile: "账户资料",

  select_tenant: "用户",
};

export default messages;
