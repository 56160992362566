import { ConfigProvider, Select, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { TickIcon } from "../../icons";
import { useIntl } from "react-intl";
const { Text } = Typography;

const getOptionLabel = (country) => {
  return `${country.name} (${country.phoneCode})`;
};

const CountryAutoComplete = (props) => {
  const { countries, onSelect = () => {}, initialValue } = props;
  const { theme } = useContext<any>(DarkModeContext);
  const [selectedValue, setSelectedValue] = useState(initialValue?.id);
  const intl = useIntl();

  const options = countries?.map((country) => ({
    label: getOptionLabel(country),
    value: country.id,
  }));

  const onChange = (value: string) => {
    setSelectedValue(value);
    onSelect({ id: value });
  };

  return (
    <ConfigProvider theme={theme}>
      <Select
        showSearch
        defaultValue={selectedValue}
        placeholder={intl.formatMessage({ id: "select_country_code" })}
        optionFilterProp="label"
        onChange={onChange}
        style={{ width: "100%", height: "56px" }}
        options={options}
        allowClear
        optionRender={(option, val) => {
          return (
            <Space style={{ color: "#0C8595" }}>
              <Text>{option.label}</Text>
              {option.value === selectedValue && (
                <TickIcon style={{ textAlign: "right" }} />
              )}
            </Space>
          );
        }}
      />
    </ConfigProvider>
  );
};

export default CountryAutoComplete;
