import { Space } from "antd";

const Overlay = (props) => {
  const {
    backgroundColor = "#FFFFFF",
    overrideStyle = {},
    children = null,
  } = props;

  return (
    <Space
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",
        backgroundColor: backgroundColor,
        ...overrideStyle,
      }}
    >
      {children ? (
        children
      ) : (
        <span style={{ visibility: "hidden", color: "white" }}>-</span>
      )}
    </Space>
  );
};

export default Overlay;
