const messages = {
  parameter: "Parameter",
  select_date: "Select Date",
  timestamp: "Timestamp",
  no_filters: "Please input filters",
  no_device: "No devices in the selected site(s)",
  no_data: "No Data",

  // excel
  excel_filename_telemetry: "Telemetry Report",
};

export default messages;
