import { createContext, useEffect } from "react";
import useTenant from "../../api/graphql/hooks/useTenant";
import * as _ from "lodash";
export const TenantIdContext = createContext({});

export const TenantIdProvider = (props: any) => {
  const { children, tenantId, isMasqAsSubTenant } = props;
  const { fetchTenantById, fetchTenantByIdData, isfetchTenantByIdLoading } =
    useTenant();

  useEffect(() => {
    if (tenantId) {
      refetchTenantById();
    }
  }, [tenantId, fetchTenantById]);

  const refetchTenantById = () => {
    fetchTenantById({
      variables: {
        id: tenantId,
      },
    });
  };

  return (
    <TenantIdContext.Provider
      value={{
        tenantId: tenantId,
        isMasqAsSubTenant: isMasqAsSubTenant,
        tenantInfo: _.get(fetchTenantByIdData, "tenantById", null),
        tenantInfoLoading: isfetchTenantByIdLoading,
        refetchTenantById: refetchTenantById,
      }}
    >
      {children}
    </TenantIdContext.Provider>
  );
};
