const messages = {
  alarms: "Alarmes",
  alarm_id: "IDENTIFIANT",
  alarm_device: "Appareil",
  alarm_site: "site",
  alarm_level: "Niveau",
  alarm_attribute: "Attribut",
  alarm_status: "Statut",
  alarm_description: "description",
  alarm_assigned_to: "Attribué à",
  alarm_last_updated_by: "Dernière mise à jour par",
  alarm_last_updated_time: "Dernière heure mise à jour",
  alarm_active: "active",
  alarm_in_progress: "En cours",
  alarm_escalated: "Dégénéré",
  alarm_resolved: "Résolue",
  alarm_deactivated: "Désactivé",
  alarm_date: "date",
  alarms_sortby: "Trier par",
  alarm_site_placeholder: "Sélectionnez Asseyez-vouse",
  alarm_device_placeholder: "Sélectionner le type de périphérique",
  alarm_level_placeholder: "Niveaux d'alarme",
  alarm_user_validation_msg: "Le nom d'utilisateur est requis",
  alarm_select_assignTo: "Sélectionnez une personne",
  alarm_assign_to: "Attribuer",
  alarm_export: "Exporter",
  alarm_excel_filename: "Rapport d'alarme",
  alarm_unassigned: "Alarme non attribuée",
  alarm_escalation_logs: "Journaux d'escalade",
  alarm_message: "message",
  alarm_escalated_by: "Dégénéré par",
  alarm_escalated_on: "Dégénéré",
  alarm_deactivated_msg: "Alarme désactivée",
  alarms_deactivated_msg: "Alarmes désactivées",
  alarm_status_update: "Statut d'alarme mis à jour avec succès.",
  alarm_message_validation_msg: "Des détails sont requis",
  alarm_message_field: "Détails supplémentaires",
  alarm_escalate_title: "Escaladez le problème",
  alarm_deactivate_btn: "Désactiver",
  alarm_escalate_btn: "Émission d'escalade",
};

export default messages;
