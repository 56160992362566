import { useEffect, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";

import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const SET_DEVICE_PARAMETER = gql`
  mutation setDeviceParams($input: SetDeviceParamsInput) {
    setDeviceParams(input: $input) {
      tenantId
      deviceId
      status
    }
  }
`;

const useDeviceActions = () => {
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);
  const { uri }: any = useContext(graphQLContext);

  const [
    setDeviceParams,
    {
      loading: isSetDeviceParamsLoading,
      error: setDeviceParamsError,
      data: setDeviceParamsResponseData,
    },
  ] = useMutation(SET_DEVICE_PARAMETER);

  useEffect(() => {
    if (setDeviceParamsError) {
      const error = setDeviceParamsError;
      handleError(error, uri, openNotification, intl);
    }
  }, [setDeviceParamsError]);

  return {
    setDeviceParams,
    isSetDeviceParamsLoading,
    setDeviceParamsError,
    setDeviceParamsResponseData,
  };
};

export default useDeviceActions;
