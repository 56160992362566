const messages = {
  // Common headers
  id: "ID",
  status: "Zustand",

  // // Device
  model: "Modell",
  add_device: "Gerät hinzufügen",
  device_name: "Gerätename",
  devices: "Geräte",
  // description: "Beschreibung",

  // // Location
  // location: "Standort",
  // site: "Grundstück",

  // Buttons
  // edit: "Bearbeiten",
  // delete: "Löschen",
  save: "Speichern",
  // update: "Aktualisieren",
  cancel: "Stornieren",
  export: "Herunterladen",
  filter: "Filter",
  apply: "Anwenden",
  reset: "Zurückstellen",
  save_changes: "Änderungen speichern",

  // // Common headers
  // settings: "Einstellungen",
  // detail: "Detail",
  // details: "Einzelheiten",
  // status: "Zustand",
  // alert: "Alarm",
  // id: "ID",
  // actions: "Aktionen",

  // // Table Headers
  // last_updated_by: "Zuletzt aktualisiert von",
  // last_updated_time: "Letzte Aktualisierungszeit",

  // // Confirm messages
  // confirm_delete: "Sind Sie sicher, dass Sie löschen möchten?",
  confirm_deactivate:
    "Sind Sie sicher, dass Sie die Funktion deaktivieren möchten?",

  // Success messages
  success_del: "Erfolgreich gelöscht",
  add_device_success: "Geräteerfolg hinzufügen",
  device_updated: "Gerät aktualisiert",
  command_sent_with_delay:
    "Befehl gesendet. Es kann einige Zeit dauern, bis die Einstellungen übernommen werden.",
  upload_success: "Erfolgreich hochgeladen",
  // device_restart_success: "Neustartbefehl wurde gesendet",
  // update_setting_success:
  //   "Ihre Einstellungen wurden erfolgreich aktualisiert. Es kann einige Zeit dauern, bis die Einstellungen übernommen werden.",
  // login_success: "Erfolgreich angemeldet",
  // update_user_password_success: "Ihr Passwort wurde erfolgreich geändert.",

  // User Input
  all: "Alle",
  // none: "Keiner",
  // select_site: "Wählen Sie Site aus",
  // select_period: "Wählen Sie Zeitraum aus",
  sort_by: "Sortieren nach",
  required_msg: "Dieses Feld ist erforderlich.",

  // Text input
  max_char: "Maximale Anzahl an Zeichen",

  // Input Placeholders
  enter_first_name: "Geben Sie den Vornamen ein",
  enter_last_name: "Geben Sie den Nachnamen ein",
  enter_email: "Geben Sie die E-Mail-Adresse ein",
  select_role: "Wählen Sie Rolle aus",
  enter_company: "Geben Sie Firma ein",
  enter_phone_number: "Geben Sie die Telefonnummer ein",

  // // BreadCrumbs
  // Home: "Startseite",
  // Device: "Geräte",
  // Alarm: "Alarm",
  // User: "Benutzer",
  // Tenants: "Mieter",
  // Telemetry: "Telemetrien",

  // // Others
  // not_available: "Nicht verfügbar",
  // date: "Datum",
  // remove: "Entfernen",
  // search: "Suchen",
  rows_per_page: "Zeilen pro Seite",

  // // Severities
  // information: "Information",
  // warning: "Warnung",
  // critical: "Entscheidend",

  // Status
  ACTIVE: "Aktiv",
  DEACTIVATED: "Deaktiviert",
  // acknowledged: "Anerkannt",
  // escalated: "Eskaliert",
  // in_progress: "Im Gange",
  // resolved: "Gelöst",

  // TenantInfo
  tenant_information: "Mieterinformationen",
  tenant_id: "Mieter-ID",
  tenant_name: "Name des Mieters",

  // // Date Range input
  // date_range_label: "Anfangsdatum Enddatum",

  // // Dates
  // start_date: "Startdatum",
  // end_date: "Endtermin",

  // Dark Mode
  dark_mode: "Dunkler Modus",
  dark_mode_auto: "Automatisch (Betriebssystemeinstellung folgen)",

  //timeperiod
  hour: "Stunde",
  day: "Tag",
  week: "Woche",

  // //email input
  // support_email_addresses: "Geben Sie Support-E-Mail-Adressen ein",
  // enter_email: "Email eingeben",
  // manage_support_email_btn: "Support-E-Mail verwalten",

  // Others
  loading: "Laden",

  // errors
  error: "Error",
  err_set_params: "Fehler in den Daten für eingestellte Parameter",
};

export default messages;
