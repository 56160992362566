const messages = {
  parameter: "范围",
  select_date: "选择日期",
  timestamp: "时间戳",
  no_filters: "请输入过滤条件",
  no_device: "所选站点中没有设备",
  no_data: "无数据",

  // excel
  excel_filename_telemetry: "遥测报告",
};

export default messages;
