import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

export const GATEWAY_DEVICE_CONFIGS = gql`
  query gatewayDeviceConfigs($tenantId: ID!, $gatewayId: ID!) {
    gatewayDeviceConfigs(tenantId: $tenantId, gatewayId: $gatewayId)
  }
`;

export const UPDATE_GATEWAY_DEVICE_CONFIG = gql`
  mutation updateGatewayDeviceConfig($input: UpdateGatewayDeviceConfigInput!) {
    updateGatewayDeviceConfig(input: $input) {
      deviceConfig
    }
  }
`;

export const REMOVE_GATEWAY_DEVICE_CONFIG = gql`
  mutation removeGatewayDeviceConfig($input: RemoveGatewayDeviceConfigInput!) {
    removeGatewayDeviceConfig(input: $input) {
      deviceConfig
    }
  }
`;

export const ADD_GATEWAY_DEVICE_CONFIG = gql`
  mutation addGatewayDeviceConfig($input: AddGatewayDeviceConfigInput!) {
    addGatewayDeviceConfig(input: $input) {
      deviceConfig
    }
  }
`;
export const RELOAD_GATEWAY_CONFIG = gql`
  mutation reloadGatewayConfig($input: ReloadGatewayConfigInput!) {
    reloadGatewayConfig(input: $input) {
      commandId
    }
  }
`;
const useGatewayDeviceConfigs = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);

  const [
    fetchGatewayDeviceConfigs,
    {
      loading: isFetchGatewayDeviceConfigsLoading,
      error: fetchGatewayDeviceConfigsError,
      data: fetchGatewayDeviceConfigsData,
    },
  ] = useLazyQuery(GATEWAY_DEVICE_CONFIGS, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    removeGatewayDeviceConfig,
    {
      loading: isremoveGatewayDeviceLoading,
      error: removeGatewayDeviceConfigError,
      data: removeGatewayDeviceConfigData,
    },
  ] = useMutation(REMOVE_GATEWAY_DEVICE_CONFIG, {
    refetchQueries: [GATEWAY_DEVICE_CONFIGS],
  });

  const [
    addGatewayDeviceConfig,
    {
      loading: isAddGatewayDeviceConfigLoading,
      error: addGatewayDeviceConfigError,
      data: addGatewayDeviceConfigData,
    },
  ] = useMutation(ADD_GATEWAY_DEVICE_CONFIG, {
    errorPolicy: "all",
  });

  const [
    updateGatewayDeviceConfig,
    {
      loading: isUpdateGatewayDeviceLoading,
      error: updateGatewayDeviceConfigError,
      data: updateGatewayDeviceConfigData,
    },
  ] = useMutation(UPDATE_GATEWAY_DEVICE_CONFIG, {});

  const [
    reloadGatewayConfig,
    {
      loading: isReloadGatewayConfigLoading,
      error: reloadGatewayConfigError,
      data: reloadGatewayConfigData,
    },
  ] = useMutation(RELOAD_GATEWAY_CONFIG, {});
  useEffect(() => {
    if (
      fetchGatewayDeviceConfigsError ||
      updateGatewayDeviceConfigError ||
      removeGatewayDeviceConfigError ||
      addGatewayDeviceConfigError ||
      reloadGatewayConfigError
    ) {
      const error =
        fetchGatewayDeviceConfigsError ||
        removeGatewayDeviceConfigError ||
        updateGatewayDeviceConfigError ||
        addGatewayDeviceConfigError ||
        reloadGatewayConfigError;
      handleError(error, uri, openNotification, intl);
    }
  }, [
    fetchGatewayDeviceConfigsError,
    removeGatewayDeviceConfigError,
    updateGatewayDeviceConfigError,
    addGatewayDeviceConfigError,
    reloadGatewayConfigError,
  ]);

  return {
    fetchGatewayDeviceConfigs,
    isFetchGatewayDeviceConfigsLoading,
    fetchGatewayDeviceConfigsError,
    fetchGatewayDeviceConfigsData,
    updateGatewayDeviceConfig,
    isUpdateGatewayDeviceLoading,
    updateGatewayDeviceConfigError,
    updateGatewayDeviceConfigData,
    removeGatewayDeviceConfig,
    isremoveGatewayDeviceLoading,
    removeGatewayDeviceConfigError,
    removeGatewayDeviceConfigData,
    addGatewayDeviceConfig,
    isAddGatewayDeviceConfigLoading,
    addGatewayDeviceConfigError,
    addGatewayDeviceConfigData,
    reloadGatewayConfig,
    isReloadGatewayConfigLoading,
    reloadGatewayConfigError,
    reloadGatewayConfigData,
  };
};

export default useGatewayDeviceConfigs;
