const messages = {
  attachment: "Anhang",
  attachments: "Anhänge",
  upload_btn: "Hochladen",
  cancel_btn: "Abbrechen",
  reset_btn: "Zurücksetzen",
  apply_btn: "Übernehmen",
  delete_btn: "Löschen",
  location: "Standort",
  coordinates: "Koordinaten",
  format: "Format",
  date: "Datum",
  site: "Standort",
  details: "Einzelheiten",
  download: "Herunterladen",
  status: "Status",
  alert: "Alarm",
  delete: "Löschen",
  new_btn: "Neu erstellen",
  delete_location_btn: "Standort Löschen",
  save_changes: "Änderungen Speichern",
  home: "Startseite",
  site_placeholder: "Standort Auswählen",
  site_validation_msg: "Bitte wählen Sie einen Standort aus.",
  name: "Name",
  name_placeholder: "Namen Eingeben",
  name_validation_msg: "Bitte geben Sie einen Namen ein.",
  location_label: "Standortname",
  location_placeholder: "Standortnamen Eingeben",
  location_validation_msg: "Bitte geben Sie den Standortnamen ein.",
  country: "Land",
  country_placeholder: "Land Eingeben",
  country_validation_msg: "Bitte wählen Sie ein Land.",
  longitude: "Längengrad",
  longitude_placeholder: "Längengrad Eingeben",
  longitude_validation_msg: "Bitte wählen Sie den Längengrad.",
  latitude: "Breitengrad",
  latitude_placeholder: "Breitengrad Eingeben",
  latitude_validation_msg: "Bitte wählen Sie den Breitengrad.",
  description: "Beschreibung",
  description_placeholder: "Beschreibung Eingeben",
  description_validation_msg: "Bitte geben Sie eine Beschreibung ein.",
  add_site_label: "Standortname",
  add_site_placeholder: "Standortnamen Eingeben",
  add_site_validation_msg: "Bitte geben Sie einen Standort ein.",
  url: "URL",
  url_placeholder: "URL Eingeben",
  url_validation_msg: "Bitte geben Sie die URL ein.",
  file_placeholder: "Dateiformat Auswählen",
  attachment_upload_msg: "Anhang erfolgreich hochgeladen",
  attachment_upload_error: "Fehler beim Hochladen des Anhangs.",
  drag_drop_label: "Ziehen und Ablegen zum Hochladen oder",
  choose_file: "Datei Wählen",
  maximun_file_size: "Maximale Dateigröße 100 MB",
  invalid_file_format: "Dateiformat nicht unterstützt.",
  file_size_validation: "Die Datei muss kleiner als 100 MB sein!",
  file_required: "Bitte laden Sie eine Datei hoch!",
  delete_attachment_title: "Anhang Löschen",
  delete_attachment_text: "Sind Sie sicher, dass Sie löschen möchten?",
  delete_location_text:
    "Sind Sie sicher, dass Sie diesen Standort löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  information_modal_title: "Anhang Informationen",
  location_delete_validation:
    "Löschen nicht möglich. Ein oder mehrere aktive Standorte existieren in diesem Standort.",
  add_location: "Standort Hinzufügen",
  add_location_msg: "Standort erfolgreich hinzugefügt",
  update_location_msg: "Standort erfolgreich aktualisiert",
  del_site_validation_msg:
    "Löschen nicht möglich. Ein oder mehrere Geräte existieren an diesem Standort.",
  add_site: "Neuen Standort Hinzufügen",
  attachment_deleted_success: "Anhang erfolgreich gelöscht",
  location_add: "hinzufügen",
};

export default messages;
