import { useEffect, useContext } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";

import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const GET_ATTACHMENTS = gql`
  query attachments(
    $tenantId: ID!
    $page: PageInput
    $sort: [String]
    $filter: [String]
  ) {
    attachments(
      tenantId: $tenantId
      page: $page
      sort: $sort
      filter: $filter
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      attachments {
        id
        site {
          id
          name
        }
        name
        description
        filename
        filesize
        createdOn
        createdBy {
          id
          firstName
          lastName
        }
        status
      }
    }
  }
`;

const UPDATE_ATTACHMENT = gql`
  mutation updateAttachment($input: UpdateAttachmentInput!) {
    updateAttachment(input: $input) {
      attachment {
        id
        name
        description
        filename
        filesize
        createdOn
        createdBy {
          id
          firstName
          lastName
        }
        status
      }
    }
  }
`;

const useAttachment = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);

  const [
    fetchAttachments,
    {
      loading: isFetchAttachmentLoading,
      error: fetchAttachmentsError,
      data: fetchAttachmentsData,
    },
  ] = useLazyQuery(GET_ATTACHMENTS, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    updateAttachment,
    {
      loading: isUpdateAttachmentLoading,
      error: updateAttachmentError,
      data: updateAttachmentResponseData,
      reset: resetUpdateAttachmentData,
    },
  ] = useMutation(UPDATE_ATTACHMENT);

  useEffect(() => {
    if (fetchAttachmentsError || updateAttachmentError) {
      const error = fetchAttachmentsError;
      handleError(error, uri, openNotification, intl);
    }
  }, [fetchAttachmentsError, updateAttachmentError]);

  return {
    isFetchAttachmentLoading,
    fetchAttachmentsError,
    fetchAttachmentsData,
    fetchAttachments,
    updateAttachment,
    isUpdateAttachmentLoading,
    updateAttachmentError,
    updateAttachmentResponseData,
    resetUpdateAttachmentData,
  };
};

export default useAttachment;
