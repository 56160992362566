const messages = {
  device_power_status: "Status",
  device_on: "Online",
  device_off: "Offline",
  sim_card: "SIM-Karte",
  sim_inserted: "Eingesetzt",
  sim_not_inserted: "Nicht eingesetzt",
  last_connection: "Letzte Verbindung",
  firmware_version: "Firmware-Version",
  device_information: "Geräteinformationen",
  connection: "Verbindung",
  device_type: "Gerätetyp",
  serial_number: "Seriennummer",
  modem_imei: "Modem IMEI",
  modem_operator: "Modem Anbieter",
  signal_strength: "Signalstärke",
  signal_strength_0: "Kein Signal",
  signal_strength_1: "Schwach",
  signal_strength_2: "Mittel",
  signal_strength_3: "Gut",
  signal_strength_4: "Ausgezeichnet",
  conn_type: "Verbindungstyp",
  conn_state: "Verbindungszustand",
  connected: "Verbunden",
  disconnected: "Getrennt",
  conn_uptime: "Verbindungszeit",

  modem_temperature: "Temperatur",
  modem_signal: "Signal",
  temperature: "Temperatur",

  modem_sent_today: "Modem heute gesendet",
  cpu_percent: "CPU-Prozentsatz",
  cpu_loadavg_1min: "CPU Durchschnittslast 1 Min",
  cpu_loadavg_5min: "CPU Durchschnittslast 5 Min",
  modem_received_today: "Modem heute empfangen",
  memory_info_percent: "Speicherinfo Prozentsatz",
  memory_info_free: "Freier Speicher",
  disk_usage_percent: "Festplattennutzungsprozentsatz",
  disk_usage_free: "Freier Speicherplatz",
  memory_info_total: "Gesamter Speicher",
  cpu_loadavg_15min: "CPU Durchschnittslast 15 Min",
  disk_usage_total: "Gesamte Festplattennutzung",

  water_leakage: "Wasserleck",
  front_door: "Vordertür",
  battery_temperature: "Batterietemperatur",

  network_packets_recv: "Netzwerkpakete empfangen",
  network_packets_sent: "Netzwerkpakete gesendet",
  gateway_last_seen: "Gateway zuletzt gesehen",
  disk_used_percent: "Prozentsatz verwendeter Festplatte",
  network_bytes_recv: "Netzwerkbytes empfangen",
  memory_used_percent: "Prozentsatz verwendeter Speicher",
  loadavg_1: "Durchschnittslast 1 Min",
  network_bytes_sent: "Netzwerkbytes gesendet",
  loadavg_15: "Durchschnittslast 15 Min",
  loadavg_5: "Durchschnittslast 5 Min",
  core_temperature: "Kern Temperatur",
  network_counter: "Netzwerkzähler",
  disk_total: "Gesamte Festplatte",
  memory_used: "Verwendeter Speicher",
  disk_used: "Verwendete Festplatte",
  disk_free: "Freier Speicherplatz",
  memory_total: "Gesamter Speicher",
  memory_free: "Freier Speicher",
  cpu: "CPU",
  uptime: "Betriebszeit",
};

export default messages;
