const messages = {
  license: "Licence",
  license_breadcrumb_title: "Licences",
  license_title: "Licences",
  license_information: "Informations sur la licence",
  license_number: "Numéro de licence",
  license_number_devices: "Nombre d'appareils",
  license_start_date: "Date de début",
  license_end_date: "Date de fin",
  tenant: "Locataire",
  current_user_usage: "Utilisation actuelle des utilisateurs",
  license_number_users: "Nombre d'utilisateurs",
  license_current_site_usage: "Utilisation actuelle du site",
  license_number_sites: "Nombre de sites",
  license_number_users_placeholder: "Entrez le nombre d'utilisateurs",
  license_number_sites_placeholder: "Entrez le nombre de sites",
  license_number_devices_placeholder: "Entrez le nombre d'appareils",
  license_current_device_usage: "Utilisation actuelle des appareils",
  tenant_export: "Exporter",
  license_export: "Exporter",
  tenant_add_btn: "Ajouter un locataire",
  license_add_btn: "Ajouter une licence",
  license_list: "Liste des licences",
  lincense_number: "Numéro de licence",
  license_tenant: "Locataire",
  license_status: "Statut",
  license_active: "Actif",
  license_deactivated: "Désactivé",
  license_expiry_start: "Début de l'expiration",
  license_expiry_end: "Fin de l'expiration",
  license_start: "Date de début",
  license_end: "Date de fin",
  license_add: "Ajouter une licence",
  license_date_pick: "Sélection de la date",
  date_pick_validation_msg: "La sélection de la date est obligatoire.",
  license_users_validation_msg: "Le nombre d'utilisateurs est requis.",
  license_sites_validation_msg: "Le nombre de sites est requis.",
  license_devices_validation_msg: "Le nombre d'appareils est requis.",
  tenant_validation_msg: "Le locataire est requis.",
  tenant_select: "Sélectionner un locataire",
  license_new_success: "Licence ajoutée avec succès.",
  license_update: "Mettre à jour",
  license_update_title: "Mettre à jour la licence",
  license_delete: "Supprimer",
  delete_license: "Supprimer la licence",
  delete_license_text: "Êtes-vous sûr de vouloir supprimer ?",
  license_deleted: "Licence supprimée avec succès.",
  tenant_admin: "Administrateur",
  tenant_manage_email: "Gérer l'email de support",
  tenant_id: "ID du locataire",
  tenant_name: "Nom du locataire",
  tenant_support_email_list: "Liste des emails de support",
  tenant_upload_license: "Télécharger la licence",
  tenant_info: "Informations sur le locataire",
  tenant_license_info: "Informations sur la licence",
  tenant_update_emails_success: "Emails de support mis à jour avec succès.",
  license_cabinets: "Armoires",
  license_dcim_controllers: "Contrôleurs DCIM",
  tenant_email_valid: "L'email n'est pas valide",
  tenant_enter_email: "Entrez les adresses e-mail de support",
  tenant_email_exist: "Déjà existant",
  tenant_email_enter: "Entrez un e-mail",
  support_email_list: "Liste de diffusion d'assistance",
};

export default messages;
