// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/ui-components/Map/index.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,UAAU;IACV,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".sidebar {\n    background-color: rgb(35 55 75 / 90%);\n    color: #fff;\n    padding: 6px 12px;\n    font-family: monospace;\n    z-index: 1;\n    position: absolute;\n    top: 0;\n    left: 0;\n    margin: 12px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
