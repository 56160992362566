import { useEffect, useState } from "react";
import { Grid } from "antd";

const getScreenSize = (screens) => {
  if (screens.xxl) return "xxl";
  else if (screens.xl) return "xl";
  else if (screens.lg) return "lg";
  else if (screens.md) return "md";
  else if (screens.sm) return "sm";
  else if (screens.xs) return "xs";
};

const { useBreakpoint } = Grid;

const useScreenOptions = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [screenSize, setScreenSize] = useState("lg");
  const screens = useBreakpoint();

  useEffect(() => {
    if (Object.keys(screens)?.length > 0) {
      const newScreenSize = getScreenSize(screens);
      setScreenSize(newScreenSize);
      // breakpoint at "lg" (i.e. 1200px)
      // if smaller than 1200px, show mobile version
      setIsMobileScreen(newScreenSize !== "xl" && newScreenSize !== "xxl");
    }
  }, [screens]);

  return { isMobileScreen, screenSize };
};

export default useScreenOptions;
