const messages = {
  alarms: "Alarms",
  alarm_id: "ID",
  alarm_device: "Device",
  alarm_site: "Site",
  alarm_level: "Level",
  alarm_attribute: "Attribute",
  alarm_status: "Status",
  alarm_description: "Description",
  alarm_assigned_to: "Assigned To",
  alarm_last_updated_by: "Last Updated By",
  alarm_last_updated_time: "Last Updated Time",
  alarm_active: "Active",
  alarm_in_progress: "In Progress",
  alarm_escalated: "Escalated",
  alarm_resolved: "Resolved",
  alarm_deactivated: "Deactivated",
  alarm_date: "Date",
  alarms_sortby: "Sort By",
  alarm_site_placeholder: "Select Site",
  alarm_device_placeholder: "Select Device Type",
  alarm_level_placeholder: "Alarm Levels",
  alarm_user_validation_msg: "User name is required",
  alarm_select_assignTo: "Select a person",
  alarm_assign_to: "Assign To",
  alarm_export: "Export",
  alarm_excel_filename: "Alarm Report",
  alarm_unassigned: "Unassign Alarm",
  alarm_escalation_logs: "Escalation Logs",
  alarm_message: "Message",
  alarm_escalated_by: "Escalated By",
  alarm_escalated_on: "Escalated On",
  alarm_deactivated_msg: "Alarm Deactivated",
  alarms_deactivated_msg: "Alarms Deactivated",
  alarm_status_update: "Alarm status updated successfully.",
  alarm_message_validation_msg: "Details are required",
  alarm_message_field: "Additional Details",
  alarm_escalate_title: "Escalate Issue",
  alarm_deactivate_btn: "Deactivate",
  alarm_escalate_btn: "Issue Escalation",
};

export default messages;
