import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Space, Typography } from "antd";
import LoadingContext from "./context/loadingContext";
import "./loadingGif.css";
import { useIntl } from "react-intl";

const { Text } = Typography;

const useLoadingGif = () => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const LoadingGif = () => {
    return (
      <LoadingContext.Provider
        value={{ isLoading: isLoading, setIsLoading: setIsLoading }}
      >
        {isLoading && (
          <Spin
            fullscreen
            indicator={
              <Space style={{ width: "200px", gap: "24px" }}>
                <Space style={{ marginBottom: "24px" }}>
                  <Text
                    style={{
                      fontFamily: "Poppins",
                      color: "#FFFFFF",
                      fontWeight: 500,
                      fontSize: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "loading" })}
                  </Text>
                </Space>
                <LoadingOutlined style={{ fontSize: 48, color: "#46BBCB" }} />
              </Space>
            }
            spinning={isLoading}
          />
        )}
      </LoadingContext.Provider>
    );
  };

  return { setIsLoading, LoadingGif };
};

export default useLoadingGif;
