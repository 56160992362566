import moment from "moment";

export const getRequiredDateFormat = (timeStamp, format = "MM-DD-YYYY") => {
  const formattedTimestamp = formatTimeStamp(timeStamp);
  return moment(formattedTimestamp).format(format);
};

export const formatTimeStamp = (timeStamp) => {
  // Format the timestamp
  if (timeStamp < 1000000000000) {
    while (timeStamp < 1000000000000) {
      timeStamp = timeStamp * 10;
    }
  } else if (timeStamp > 9999999999999) {
    while (timeStamp > 9999999999999) {
      timeStamp = timeStamp / 10;
    }
  }

  return timeStamp;
};
