const messages = {
  alarms: "alarm",
  alarm_id: "AUSWEIS",
  alarm_device: "Gerät",
  alarm_site: "Website",
  alarm_level: "Ebene",
  alarm_attribute: "Attribut",
  alarm_status: "status",
  alarm_description: "Beschreibung",
  alarm_assigned_to: "Zugewiesen an",
  alarm_last_updated_by: "Zuletzt aktualisiert von",
  alarm_last_updated_time: "Letzte aktualisierte Zeit",
  alarm_active: "Aktiv",
  alarm_in_progress: "Im Gange",
  alarm_escalated: "Eskaliert",
  alarm_resolved: "Gelöst",
  alarm_deactivated: "Deaktiviert",
  alarm_date: "Datum",
  alarms_sortby: "sortieren",
  alarm_site_placeholder: "Site auswählen",
  alarm_device_placeholder: "Wählen Sie den Gerätetyp",
  alarm_level_placeholder: "Alarmpegel",
  alarm_user_validation_msg: "Benutzername ist erforderlich",
  alarm_select_assignTo: "Wählen Sie eine Person aus",
  alarm_assign_to: "Zuweisen an",
  alarm_export: "export",
  alarm_excel_filename: "Alarmbericht",
  alarm_unassigned: "Ungewissem Alarm",
  alarm_escalation_logs: "Eskalationsprotokolle",
  alarm_message: "Nachricht",
  alarm_escalated_by: "Eskaliert von",
  alarm_escalated_on: "Eskaliert",
  alarm_deactivated_msg: "Alarm deaktiviert",
  alarms_deactivated_msg: "Alarme deaktiviert",
  alarm_status_update: "Alarmstatus erfolgreich aktualisiert.",
  alarm_message_validation_msg: "Details sind erforderlich",
  alarm_message_field: "Zusätzliche Details",
  alarm_escalate_title: "Ausgabe eskalieren",
  alarm_deactivate_btn: "Deaktivieren",
  alarm_escalate_btn: "Ausgabe Eskalation",
};

export default messages;
