import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import { LOCALES } from "./locales";

export const useLocale = () => {
  const getDefaultLocale = () => {
    return window.localStorage.getItem("locale") || "en-GB";
  };

  const setLocale = (loc: string) => {
    window.localStorage.setItem("locale", loc);
    window.dispatchEvent(new Event("localeChanged"));
  };

  const useLocaleListener = (setLocale: Function) => {
    window.addEventListener("localeChanged", () => {
      setLocale(window.localStorage.getItem("locale"));
    });
  };

  return { getDefaultLocale, setLocale, useLocaleListener };
};

export const TranslationProvider = (props: any) => {
  const { children, locale } = props;

  const onIntlError = (err) => {
    //console.log('err', err.message);
  };

  return (
    <IntlProvider
      key={locale}
      messages={messages[locale]}
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
      onError={onIntlError}
    >
      {children}
    </IntlProvider>
  );
};
