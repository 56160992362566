import common_messages from "./common/en_GB";
// import error_messages from "./error/en_GB";
import navbar_messages from "./navbar/en_GB";
import home_messages from "./home/en_GB";
import alarm_messages from "./alarms/en_GB";
// import sites_messages from "./sites/en_GB";
import device_messages from "./device/en_GB";
import user_messages from "./user/en_GB";
// import tenant_messages from "./tenant/en_GB";
import telemetry_messages from "./telemetry/en_GB";
import tenant_messages from "./tenant/en_GB.ts";
// import telemetry_messages from "./telemetry/en_GB";
// import analytics_messages from "./analytics/en_GB";
// import gateway_messages from "./gateway/en_GB";
import location_messages from "./location/en_GB";
import keys from "./keys/en_GB";

const messages = {
  ...common_messages,
  // ...error_messages,
  ...navbar_messages,
  ...home_messages,
  ...alarm_messages,
  // ...sites_messages,
  ...device_messages,
  ...user_messages,
  // ...tenant_messages,
  ...telemetry_messages,
  ...tenant_messages,
  // ...telemetry_messages,
  // ...analytics_messages,
  // ...gateway_messages,
  ...location_messages,
  ...keys,
};

export default messages;
