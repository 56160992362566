const messages = {
  device_power_status: "Statut",
  device_on: "En ligne",
  device_off: "Hors ligne",
  sim_card: "Carte SIM",
  sim_inserted: "Insérée",
  sim_not_inserted: "Non insérée",
  last_connection: "Dernière connexion",
  firmware_version: "Version du firmware",
  device_information: "Informations sur le dispositif",
  connection: "Connexion",
  device_type: "Type de dispositif",
  serial_number: "Numéro de série",
  modem_imei: "IMEI du modem",
  modem_operator: "Opérateur du modem",
  signal_strength: "Force du signal",
  signal_strength_0: "Pas de signal",
  signal_strength_1: "Faible",
  signal_strength_2: "Médiocre",
  signal_strength_3: "Bon",
  signal_strength_4: "Excellent",
  conn_type: "Type de connexion",
  conn_state: "État de connexion",
  connected: "Connecté",
  disconnected: "Déconnecté",
  conn_uptime: "Temps de connexion",

  modem_temperature: "Température",
  modem_signal: "Signal",
  temperature: "Température",

  modem_sent_today: "Modem envoyé aujourd'hui",
  cpu_percent: "Pourcentage CPU",
  cpu_loadavg_1min: "Moyenne de charge CPU 1 min",
  cpu_loadavg_5min: "Moyenne de charge CPU 5 min",
  modem_received_today: "Modem reçu aujourd'hui",
  memory_info_percent: "Pourcentage d'informations sur la mémoire",
  memory_info_free: "Mémoire libre",
  disk_usage_percent: "Pourcentage d'utilisation du disque",
  disk_usage_free: "Espace disque libre",
  memory_info_total: "Mémoire totale",
  cpu_loadavg_15min: "Moyenne de charge CPU 15 min",
  disk_usage_total: "Utilisation totale du disque",

  water_leakage: "Fuite d'eau",
  front_door: "Porte avant",
  battery_temperature: "Température de la batterie",

  network_packets_recv: "Paquets réseau reçus",
  network_packets_sent: "Paquets réseau envoyés",
  gateway_last_seen: "Passerelle dernière vue",
  disk_used_percent: "Pourcentage de disque utilisé",
  network_bytes_recv: "Octets réseau reçus",
  memory_used_percent: "Pourcentage de mémoire utilisée",
  loadavg_1: "Moyenne de charge 1 min",
  network_bytes_sent: "Octets réseau envoyés",
  loadavg_15: "Moyenne de charge 15 min",
  loadavg_5: "Moyenne de charge 5 min",
  core_temperature: "Température du cœur",
  network_counter: "Compteur réseau",
  disk_total: "Disque total",
  memory_used: "Mémoire utilisée",
  disk_used: "Disque utilisé",
  disk_free: "Disque libre",
  memory_total: "Mémoire totale",
  memory_free: "Mémoire libre",
  cpu: "CPU",
  uptime: "Temps de disponibilité",
};

export default messages;
