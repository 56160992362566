import { Button, ConfigProvider } from "antd";
import { useContext } from "react";
import { DarkModeContext } from "../../datwyler-mfe-shared-components";

export const buttonStyle = {
  padding: "12px 24px 12px 24px",
  borderRadius: "4px",
  gap: "8px",
  height: "48px",
};

const BasicButton = (props) => {
  const {
    type = null,
    style = {},
    icon = null,
    onClick = () => {},
    danger = null,
    children,
    htmlType = "button",
    disabled = false,
  } = props;
  const { theme } = useContext<any>(DarkModeContext);

  return (
    <ConfigProvider theme={theme}>
      <Button
        type={type}
        style={{ ...buttonStyle, ...style }}
        icon={icon}
        onClick={onClick}
        danger={danger}
        htmlType={htmlType}
        disabled={disabled}
      >
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default BasicButton;
