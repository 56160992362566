export const getSiteOptions = (locations) => {
  // get dropdown options for sites using the list of locations given
  const allSites = getAllSites(locations);

  return allSites.map((site) => ({
    label: site?.name,
    value: site?.id,
  }));
};

export const getAllSites = (locations) => {
  let allSites = [];

  if (!locations || locations.length < 1) {
    return [];
  }

  locations.forEach((location) => {
    if (location.sites) allSites = allSites.concat(location.sites);
  });

  return allSites;
};
