export const DatwylerLogo = (props) => {
  const { style, height = "40", width = "216" } = props;
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={`0 0 216 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.0666 19.8457L43.3898 21.169L28.8369 35.7247L27.5137 34.4014L42.0666 19.8457Z"
        fill="#008393"
      />
      <path
        d="M23.5425 9.26174L24.8657 7.93848L38.0982 21.1709L36.7736 22.4928L23.5425 9.26174Z"
        fill="#DF1417"
      />
      <path
        d="M20.8975 11.9092L22.2193 10.5859L31.482 19.8473L30.1588 21.1705L20.8975 11.9092Z"
        fill="#DF1417"
      />
      <path
        d="M19.5738 2.64648L20.897 3.96974L14.2822 10.586L12.9575 9.26274L19.5738 2.64648Z"
        fill="#008393"
      />
      <path
        d="M2.37355 19.8477L20.8962 38.3717L19.5744 39.6936L1.05029 21.1695L2.37355 19.8477Z"
        fill="#008393"
      />
      <path
        d="M10.313 17.2008L19.5743 7.93945L20.8976 9.2613L11.6348 18.5227L10.313 17.2008Z"
        fill="#DF1417"
      />
      <path
        d="M20.897 35.7242L30.1583 26.4629L31.4816 27.7847L22.2202 37.0461L20.897 35.7242Z"
        fill="#DF1417"
      />
      <path
        d="M22.2203 0L36.776 14.5544L35.4528 15.8776L20.8984 1.32185L22.2203 0Z"
        fill="#008393"
      />
      <path
        d="M5.01955 17.2012L8.98928 21.1695L7.66602 22.4928L3.69629 18.523L5.01955 17.2012Z"
        fill="#008393"
      />
      <path
        d="M66.3878 19.8718C66.3878 23.0378 64.2314 24.8302 60.9828 24.8302H58.7704V14.8589H60.9828C64.2314 14.8589 66.3878 16.6806 66.3878 19.8158V19.8718ZM71.9608 19.7318C71.9608 14.0747 67.9001 10.042 60.8988 10.042H53.3359V29.6457H60.7868C67.8441 29.6457 71.9608 25.4449 71.9608 19.7878V19.7318Z"
        fill="#DF1417"
      />
      <path
        d="M91.5596 14.8025H97.4421V29.6467H102.875V14.8025H108.756V10.043H91.5596V14.8025Z"
        fill="#DF1417"
      />
      <path
        d="M149.838 17.1837L145.805 10.0424H140.039H139.615H134.408L130.992 21.3845L127.211 9.98633H122.534L118.753 21.3845L115.335 10.0424H109.596L116.148 29.7875H120.88L124.803 18.5L128.751 29.7875H133.484L139.889 10.4932L147.065 22.3087V29.646H152.526V22.226L159.976 10.0424H153.899L149.838 17.1837Z"
        fill="#DF1417"
      />
      <path
        d="M166.866 10.043H161.431V29.6467H176.359V24.8858H166.866V10.043Z"
        fill="#DF1417"
      />
      <path
        d="M183.836 21.9172H193.247V17.631H183.836V14.6625H194.227V10.043H178.458V29.6467H194.367V25.0258H183.836V21.9172Z"
        fill="#DF1417"
      />
      <path
        d="M208.845 17.1273C208.845 18.557 207.781 19.4518 205.932 19.4518H202.348V14.7469H205.904C207.725 14.7469 208.845 15.531 208.845 17.0713V17.1273ZM210.274 22.8138C212.711 21.7762 214.306 19.7878 214.306 16.7927V16.7367C214.306 14.8029 213.718 13.2906 212.569 12.1704C211.253 10.8261 209.181 10.042 206.186 10.042H196.913V29.6457H202.348V23.7086H204.673H204.729L208.677 29.6457H214.95L210.274 22.8138Z"
        fill="#DF1417"
      />
      <path
        d="M85.1501 9.90137H79.9131L77.8379 14.8093H87.2239L85.1501 9.90137Z"
        fill="#DF1417"
      />
      <path
        d="M82.6215 16.6797L84.6743 21.9167H80.2775L82.3568 16.6797H77.0484L71.5664 29.6461H77.2809L78.6811 26.1455H86.2426L87.6708 29.6461H93.4959L88.0153 16.6797H82.6215Z"
        fill="#DF1417"
      />
    </svg>
  );
};

export const DatwylerLogoDark = (props) => {
  const { style, height = "40", width = "216" } = props;
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 216 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.0666 19.8457L43.3898 21.169L28.8369 35.7247L27.5137 34.4014L42.0666 19.8457Z"
        fill="white"
      />
      <path
        d="M23.5425 9.26174L24.8657 7.93848L38.0982 21.1709L36.7736 22.4928L23.5425 9.26174Z"
        fill="white"
      />
      <path
        d="M20.8975 11.9092L22.2193 10.5859L31.482 19.8473L30.1588 21.1705L20.8975 11.9092Z"
        fill="white"
      />
      <path
        d="M19.5738 2.64648L20.897 3.96974L14.2822 10.586L12.9575 9.26274L19.5738 2.64648Z"
        fill="white"
      />
      <path
        d="M2.37355 19.8477L20.8962 38.3717L19.5744 39.6936L1.05029 21.1695L2.37355 19.8477Z"
        fill="white"
      />
      <path
        d="M10.313 17.2008L19.5743 7.93945L20.8976 9.2613L11.6348 18.5227L10.313 17.2008Z"
        fill="white"
      />
      <path
        d="M20.897 35.7242L30.1583 26.4629L31.4816 27.7847L22.2202 37.0461L20.897 35.7242Z"
        fill="white"
      />
      <path
        d="M22.2203 0L36.776 14.5544L35.4528 15.8776L20.8984 1.32185L22.2203 0Z"
        fill="white"
      />
      <path
        d="M5.01955 17.2012L8.98928 21.1695L7.66602 22.4928L3.69629 18.523L5.01955 17.2012Z"
        fill="white"
      />
      <path
        d="M66.3878 19.8718C66.3878 23.0378 64.2314 24.8302 60.9828 24.8302H58.7704V14.8589H60.9828C64.2314 14.8589 66.3878 16.6806 66.3878 19.8158V19.8718ZM71.9608 19.7318C71.9608 14.0747 67.9001 10.042 60.8988 10.042H53.3359V29.6457H60.7868C67.8441 29.6457 71.9608 25.4449 71.9608 19.7878V19.7318Z"
        fill="white"
      />
      <path
        d="M91.5596 14.8025H97.4421V29.6467H102.875V14.8025H108.756V10.043H91.5596V14.8025Z"
        fill="white"
      />
      <path
        d="M149.838 17.1837L145.805 10.0424H140.039H139.615H134.408L130.992 21.3845L127.211 9.98633H122.534L118.753 21.3845L115.335 10.0424H109.596L116.148 29.7875H120.88L124.803 18.5L128.751 29.7875H133.484L139.889 10.4932L147.065 22.3087V29.646H152.526V22.226L159.976 10.0424H153.899L149.838 17.1837Z"
        fill="white"
      />
      <path
        d="M166.866 10.043H161.431V29.6467H176.359V24.8858H166.866V10.043Z"
        fill="white"
      />
      <path
        d="M183.836 21.9172H193.247V17.631H183.836V14.6625H194.227V10.043H178.458V29.6467H194.367V25.0258H183.836V21.9172Z"
        fill="white"
      />
      <path
        d="M208.845 17.1273C208.845 18.557 207.781 19.4518 205.932 19.4518H202.348V14.7469H205.904C207.725 14.7469 208.845 15.531 208.845 17.0713V17.1273ZM210.274 22.8138C212.711 21.7762 214.306 19.7878 214.306 16.7927V16.7367C214.306 14.8029 213.718 13.2906 212.569 12.1704C211.253 10.8261 209.181 10.042 206.186 10.042H196.913V29.6457H202.348V23.7086H204.673H204.729L208.677 29.6457H214.95L210.274 22.8138Z"
        fill="white"
      />
      <path
        d="M85.1501 9.90137H79.9131L77.8379 14.8093H87.2239L85.1501 9.90137Z"
        fill="white"
      />
      <path
        d="M82.6215 16.6797L84.6743 21.9167H80.2775L82.3568 16.6797H77.0484L71.5664 29.6461H77.2809L78.6811 26.1455H86.2426L87.6708 29.6461H93.4959L88.0153 16.6797H82.6215Z"
        fill="white"
      />
    </svg>
  );
};
