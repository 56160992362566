const messages = {
  device_power_status: "Status",
  device_on: "Online",
  device_off: "Offline",
  sim_card: "Sim Card",
  sim_inserted: "Inserted",
  sim_not_inserted: "Not Inserted",
  last_connection: "Last Connection",
  firmware_version: "Firmware Version",
  device_information: "Device Information",
  connection: "Connection",
  device_type: "Device Type",
  serial_number: "Serial Number",
  modem_imei: "Modem IMEI",
  modem_operator: "Modem Operator",
  signal_strength: "Signal Strength",
  signal_strength_0: "No Signal",
  signal_strength_1: "Poor",
  signal_strength_2: "Fair",
  signal_strength_3: "Good",
  signal_strength_4: "Excellent",
  conn_type: "Connection Type",
  conn_state: "Connection State",
  connected: "Connected",
  disconnected: "Disconnected",
  conn_uptime: "Connection Uptime",

  modem_temperature: "Temperature",
  modem_signal: "Signal",
  temperature: "Temperature",

  modem_sent_today: "Modem Sent Today",
  cpu_percent: "CPU Percent",
  cpu_loadavg_1min: "CPU Avg Load 1 min",
  cpu_loadavg_5min: "CPU Avg Load 5 min",
  modem_received_today: "Modem Received Today",
  memory_info_percent: "Memory info Percent",
  memory_info_free: "Memory Info Free",
  disk_usage_percent: "Disk Usage Percent",
  disk_usage_free: "Disk Usage Free",
  memory_info_total: "Memory Info Total",
  cpu_loadavg_15min: "CPU Avg Load 15 min",
  disk_usage_total: "Disk Usage Total",

  water_leakage: "Water Leakage",
  front_door: "Front Door",
  battery_temperature: "Battery Temperature",

  network_packets_recv: "Network Packets Recv",
  network_packets_sent: "Network Packets Sent",
  gateway_last_seen: "Gateway Last Seen",
  disk_used_percent: "Disk Used Percent",
  network_bytes_recv: "Network Bytes Recv",
  memory_used_percent: "Memory Used Percent",
  loadavg_1: "Avg Load 1 min",
  network_bytes_sent: "Network Bytes Sent",
  loadavg_15: "Avg Load 15 min",
  loadavg_5: "Avg Load 5 min",
  core_temperature: "Core Temperature",
  network_counter: "Network Counter",
  disk_total: "Disk Total",
  memory_used: "Memory Used",
  disk_used: "Disk Used",
  disk_free: "Disk Free",
  memory_total: "Memory Total",
  memory_free: "Memory Free",
  cpu: "CPU",
  uptime: "Uptime",
  humidity: "Humidity",
};

export default messages;
