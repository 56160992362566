import { useEffect, useContext } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const GET_SUBTENANTS = gql`
  query subTenants(
    $tenantId: ID!
    $page: PageInput
    $sort: [String]
    $filter: [String]
  ) {
    subTenants(tenantId: $tenantId, page: $page, sort: $sort, filter: $filter) {
      page {
        size
        number
        totalPages
        totalElements
      }
      tenants {
        id
        name
        level
        status
        managed
      }
    }
  }
`;

const useSubTenant = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);

  const [
    fetchSubTenant,
    {
      loading: isfetchSubTenantLoading,
      error: fetchSubTenantError,
      data: fetchSubTenantData,
    },
  ] = useLazyQuery(GET_SUBTENANTS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (fetchSubTenantError) {
      const error = fetchSubTenantError;
      handleError(error, uri, openNotification, intl);
    }
  }, [fetchSubTenantError]);

  return {
    fetchSubTenant,
    isfetchSubTenantLoading,
    fetchSubTenantData,
    fetchSubTenantError,
  };
};

export default useSubTenant;
