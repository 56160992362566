import { useEffect, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";
import { NotificationContext } from "../../../providers/NotificationsProvider";

const GET_COUNTRIES = gql`
  {
    countries {
      id
      name
      iso2Code
      phoneCode
      status
    }
  }
`;

const useCountry = () => {
  const { uri }: any = useContext(graphQLContext);
  const { loading, error, data } = useQuery(GET_COUNTRIES);
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);

  useEffect(() => {
    if (error) {
      handleError(error, uri, openNotification, intl);
    }
  }, [error]);

  return { loading, error, data };
};

export default useCountry;
