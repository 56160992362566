import { Button, ConfigProvider, Divider, Flex, Popover, Space } from "antd";
import { MoreIcon } from "../../icons";
import { useContext } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";

export type MoreButtonItemProps = {
  label?: string;
  onClick?: Function;
  danger?: Boolean;
};

const MoreButton = (props) => {
  const {
    items,
    placement = "bottomRight",
    trigger = "click",
    customContent = null,
  } = props;

  const { theme } = useContext<any>(DarkModeContext);

  const content = () => {
    // customContent to override the menu, you can put in whatever you want in the popover
    if (customContent) return customContent;
    return (
      <Space direction="vertical" style={{ minWidth: "168px", gap: "0px" }}>
        {items.map((item, index) => {
          if (item.label === "divider")
            return (
              <Flex style={{ width: "100%" }}>
                <Divider
                  key={index}
                  style={{ margin: "1px 20px", minWidth: "unset" }}
                />
              </Flex>
            );
          else
            return (
              <Button
                key={index}
                type="text"
                danger={item.danger}
                onClick={item.onClick}
                disabled={item.disabled}
                style={{
                  width: "100%",
                  justifyContent: "start",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "16px",
                  minHeight: "52px",
                }}
              >
                {item.label}
              </Button>
            );
        })}
      </Space>
    );
  };

  return (
    <ConfigProvider theme={theme}>
      <Popover
        content={content}
        trigger={trigger}
        placement={placement}
        arrow={false}
        overlayInnerStyle={{ padding: "0px" }}
      >
        <Button
          icon={<MoreIcon width={24} height={24} />}
          style={{ border: "none", boxShadow: "none", background: "none" }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </Popover>
    </ConfigProvider>
  );
};

export default MoreButton;
