import React, { useContext } from "react";
import * as _ from "lodash";
import { UserContext } from "../providers/UserProvider";
import useLoadingGif from "../hooks/useLoadingGif";
import { LICENSE_STATUS, ADMIN } from "../constants";
import {
  ADMIN_PERMISSIONS,
  TENANT_PERMISSIONS,
  OPERATOR_PERMISSIONS,
  PAGE_WITHOUT_LICENSE,
} from "./permissions";
import { TenantIdContext } from "../providers/TenantIdProvider";
//Define user roles and permissions.

const roles: Record<string, string[]> = {
  ADMIN: [...ADMIN_PERMISSIONS],
  TENANT: [...TENANT_PERMISSIONS],
  OPERATOR: [...OPERATOR_PERMISSIONS],
};

export const hasAccess = (
  role: string,
  permission: string,
  licenseInfo?: any
): boolean => {
  const licenseStatus = _.get(licenseInfo, "status");

  const isValidLicense =
    licenseStatus === LICENSE_STATUS.ACTIVE ||
    licenseStatus === LICENSE_STATUS.EXPIRING;

  if (PAGE_WITHOUT_LICENSE.includes(permission)) {
    return roles[role] && roles[role].includes(permission);
  }
  return roles[role] && roles[role].includes(permission) && isValidLicense;
};

export const withPermission =
  (requiredPermission: string) => (WrappedComponent: React.FC<any>) => {
    const WithPermission: React.FC<any> = (props) => {
      const { LoadingGif } = useLoadingGif();
      const userContext = useContext<any>(UserContext);
      const { tenantInfo, tenantInfoLoading }: any =
        useContext(TenantIdContext);
      const licenseInfo = _.get(tenantInfo, "license", null);
      const {
        user: { role },
      } = userContext;
      if (!role || tenantInfoLoading || !tenantInfo) {
        return <LoadingGif />;
      } else if (role && !hasAccess(role, requiredPermission, licenseInfo)) {
        role === ADMIN
          ? window.location.replace("/admin")
          : window.location.replace("/user/profile");
        return null;
      }
      return <WrappedComponent {...props} />;
    };
    return WithPermission;
  };
