import { Select, Checkbox } from "antd";
import { useContext } from "react";
import styled from "styled-components";
import { DarkModeContext } from "../../providers/DarkModeProvider";

interface StyledCheckboxProps {
  isdarkmode: string;
}

const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) =>
      props.isdarkmode === "true" ? "#FFFFFF" : "#292D32"};
    border: unset;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${(props) =>
      props.isdarkmode === "true" ? "#292D32" : "#FFFFFF"};
  }

  .ant-checkbox-inner {
    background: unset;
    border: 1.5px solid #8f9399;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    border-radius: 8px !important;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const BasicSelectMultiple = (props) => {
  // Do not set state when handleOnChange, antd triggers a re render and closes the dialog
  const {
    options,
    placeholder = "",
    onChange,
    defaultValue,
    maxTagCount = 2,
  } = props;
  const { isDarkMode } = useContext<any>(DarkModeContext);

  return (
    <StyledSelect
      mode="multiple"
      optionFilterProp="label"
      allowClear
      style={{ width: "100%", height: "56px" }}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      maxTagCount={maxTagCount}
      menuItemSelectedIcon={(option) => {
        return (
          <StyledCheckbox
            isdarkmode={isDarkMode.toString()}
            checked={option.isSelected}
          />
        );
      }}
    />
  );
};

export default BasicSelectMultiple;
