import { useContext } from "react";
import { styled } from "styled-components";
import { DarkModeContext } from "../providers/DarkModeProvider";
import { ConfigProvider, Card, Button, Input, Typography } from "antd";

export const withConfigProvider = (Component) => {
  return (props) => {
    const { theme } = useContext<any>(DarkModeContext);
    return (
      <ConfigProvider theme={theme}>
        <Component {...props} />
      </ConfigProvider>
    );
  };
};

const ThemedButton = withConfigProvider(Button);
const ThemedCard = withConfigProvider(Card);
const ThemedInput = withConfigProvider(Input);
const ThemedText = withConfigProvider(Typography.Text);

export const Body16RegularPrimary = styled.span`
  //styleName: Body/16px/Regular;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;

export const Body16RegularTertiary = styled.span`
  //styleName: Body/16px/Regular;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textTertiary};
`;

export const Body16RegularSecondary = styled.span`
  //styleName: Body/16px/Regular;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textSecondary};
`;

export const Body14RegularSecondary = styled.span`
  //styleName: Body/14px/Regular;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.datwylerText.textSecondary};
`;

export const Body14RegularTertiary = styled.span`
  //styleName: Body/14px/Regular;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.datwylerText.textTertiary};
`;

export const Body14RegularPrimary = styled.span`
  //styleName: Body/14px/Regular;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;

export const Body16MediumPrimary = styled.span`
  //styleName: Body/16px/Medium;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;

export const Body16MediumSecondary = styled.span`
  //styleName: Body/16px/Medium;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textSecondary};
`;

export const Caption12Secondary = styled.span`
  //styleName: Caption/12px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.datwylerText.textSecondary};
`;

export const Caption12Tertiary = styled.span`
  //styleName: Caption/12px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => props.theme.datwylerText.textTertiary};
`;
export const Title18MediumPrimary = styled.span`
  //styleName: Title/18px/medium;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;

export const StyledCard = styled(ThemedCard)`
  .ant-card-body {
    padding: 0px;
    overflow-y: auto;
    max-height: 600px;
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.datwylerSurface.surfaceTertiary}
      transparent;
  }
`;

export const StyledCard2 = styled(ThemedCard)`
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 100%;
  scrollbar-color: ${(props) => props.theme.datwylerSurface.surfaceTertiary}
    transparent;
  .ant-card-body {
    padding: 0px;
  }
`;

export const Button16Invert = styled(ThemedButton)`
  //styleName: Button/16px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textInvert};
  height: 48px;
`;

export const Button16Primary = styled(ThemedButton)`
  //styleName: Button/16px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
  height: 48px;
`;

export const InputField = styled(ThemedInput)`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  height: 56px;
`;

export const Button14Brand = styled(ThemedButton)`
  //styleName: Button/14px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.datwylerText.textBrand};
`;

export const Title = styled(ThemedText)`
  //styleName: Title/28px;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;

export const Title20Primary = styled.span`
  //styleName: Title/20px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;
