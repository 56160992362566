import { useRef, useEffect, useState, useCallback } from "react";
import { useTheme } from "styled-components";
// @ts-ignore
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2Fpd2VpZmFuIiwiYSI6ImNscTR5bW4wYzBja3UyanEwaGNrMnEybTAifQ.xJZIM59xDINyPHgvIs_SqA";
import "./index.css";

const Map = ({ locations, onMarkerClick }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(53.8478);
  const [lat, setLat] = useState(23.4241);
  const [zoom, setZoom] = useState(2);
  const theme = useTheme();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    const darkMode = theme.datwylerSurface.SurfacePrimary === "#191A24";
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: darkMode
        ? "mapbox://styles/mapbox/dark-v11"
        : "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
      trackResize: true,
    });

    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  const getPinColor = useCallback(
    (highestSeverity) => {
      switch (highestSeverity) {
        case "INFORMATION":
          return theme.datwylerIcon.iconSuccess;
        case "WARNING":
          return theme.datwylerIcon.iconWarning;
        case "CRITICAL":
          return theme.datwylerIcon.iconError;
        default:
          return theme.datwylerIcon.iconSuccess;
      }
    },
    [theme]
  );

  useEffect(() => {
    const currentMarkers = document.querySelectorAll(".mapboxgl-marker");
    currentMarkers.forEach((marker) => marker.remove());

    (locations || []).map((location) => {
      if (map.current) {
        const { longitude, latitude, highestSeverity } = location;
        const marker = new mapboxgl.Marker({
          color: getPinColor(highestSeverity),
        })
          .setLngLat([longitude, latitude])
          .addTo(map.current);
        marker.getElement().addEventListener("click", () => {
          onMarkerClick(location);
        });
      }
    });
  }, [locations, getPinColor, onMarkerClick]);

  const useResize = (handler) => {
    useEffect(() => {
      window.addEventListener("resize", handler);

      return () => {
        window.removeEventListener("resize", handler);
      };
    }, [handler]);
  };

  const onResize = () => {
    setTimeout(() => map.current.resize());
  };

  useResize(onResize);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{ borderRadius: 4, height: "100%" }}
    />
  );
};

export default Map;
