import { ConfigProvider, Flex, Typography } from "antd";
import SingleSelect from "../SingleSelect";
import { useIntl } from "react-intl";
import { DarkModeContext } from "../../datwyler-mfe-shared-components";
import { useContext } from "react";

const { Text } = Typography;

const BasicSortBy = (props) => {
  const { options, value, onChange, justify = "start" } = props;
  const intl = useIntl();
  const { theme } = useContext<any>(DarkModeContext);

  return (
    <ConfigProvider theme={theme}>
      <Flex justify={justify} align="center" style={{ width: "100%" }}>
        <Text
          style={{
            color: "#8F9399",
            fontSize: "16px",
            fontWeight: "400",
            marginRight: "16px",
          }}
        >
          {intl.formatMessage({ id: "sort_by" })}
        </Text>
        <SingleSelect
          options={options}
          variant={"borderless"}
          value={value}
          onChange={onChange}
          style={{ minWidth: 98 }}
          popupMatchSelectWidth={168}
        />
      </Flex>
    </ConfigProvider>
  );
};

export default BasicSortBy;
