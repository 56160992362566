import { useMemo, createContext, useContext } from "react";
import { notification, ConfigProvider, type NotificationArgsProps } from "antd";
import { DarkModeContext } from "../DarkModeProvider";

type NotificationType = "success" | "info" | "warning" | "error";
type NotificationPlacement = NotificationArgsProps["placement"];
type NotificationParamsType = {
  type: NotificationType;
  title: String;
  description: String;
  placement: NotificationPlacement;
};

export const NotificationContext = createContext({});

export const NotificationProvider = (props) => {
  const { children } = props;
  const [api, contextHolder] = notification.useNotification();
  const { theme } = useContext<any>(DarkModeContext);

  const openNotification = (params: NotificationParamsType) => {
    const {
      type = "info",
      title = "",
      description = "",
      placement = "topRight",
    } = params;

    api[type]({
      message: title,
      description: description,
      placement: placement,
    });
  };

  const contextValue = useMemo(
    () => ({
      openNotification: openNotification,
    }),
    []
  );

  return (
    <ConfigProvider theme={theme}>
      <NotificationContext.Provider value={contextValue}>
        {contextHolder}
        {children}
      </NotificationContext.Provider>
    </ConfigProvider>
  );
};
