import { Select } from "antd";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    border-radius: 8px !important;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const BasicMultipleInput = (props) => {
  const { placeholder, style, onChange } = props;

  return (
    <StyledSelect
      mode="tags"
      style={{ width: "100%", height: "57.6px", ...style }}
      placeholder={placeholder}
      onChange={onChange}
      tokenSeparators={[","]}
      options={[]}
      dropdownStyle={{ visibility: "hidden" }}
      suffixIcon={null}
    />
  );
};

export default BasicMultipleInput;
