const messages = {
  parameter: "Parameter",
  select_date: "Wählen Sie Datum aus",
  timestamp: "Zeitstempel",
  no_filters: "Bitte geben Sie Filter ein",
  no_device: "Keine Geräte an den ausgewählten Standorten",
  no_data: "Keine Daten",

  // excel
  excel_filename_telemetry: "Telemetriebericht",
};

export default messages;
