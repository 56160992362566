import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Modal,
  Row,
  Typography,
} from "antd";
import CloseIcon from "../../icons/svg/CloseIcon.svg?component";
import useScreenOptions from "../../hooks/useScreenOptions";
import { useContext } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";

const { Text } = Typography;

const BasicModal = (props) => {
  // pass footer={null} if you want to remove footer
  const {
    title,
    isOpen,
    children,
    handleOk,
    handleCancel,
    footer,
    okText,
    okButtonProps,
    cancelText,
    cancelButtonProps,
    modalRender,
    width = "520px",
    okType = "primary",
    afterClose = () => {},
  } = props;

  const { isMobileScreen } = useScreenOptions();
  const { isDarkMode, theme } = useContext<any>(DarkModeContext);

  const getTitle = () => {
    if (typeof title !== "string") return title;
    return (
      <Row style={{ marginBottom: "24px" }} justify={"space-between"}>
        <Col>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {title}
          </Text>
        </Col>
        <Col>
          <Button
            type="text"
            onClick={handleCancel}
            icon={<CloseIcon width={"24px"} height={"24px"} />}
          ></Button>
        </Col>
        <Col span={24}>
          <Divider
            style={{
              margin: "24px 0px 0px -24px",
              width: "calc(100% + 48px)",
              borderColor: isDarkMode ? "#5A5C5F" : "#E1E1E4",
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <ConfigProvider theme={theme}>
      <Modal
        destroyOnClose
        centered={!isMobileScreen}
        title={getTitle()}
        open={isOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={footer}
        okText={okText}
        okButtonProps={okButtonProps}
        cancelText={cancelText}
        cancelButtonProps={cancelButtonProps}
        closable={false}
        modalRender={modalRender}
        width={isMobileScreen ? "90%" : width}
        okType={okType}
        afterClose={afterClose}
      >
        {children}
      </Modal>
    </ConfigProvider>
  );
};

export default BasicModal;
