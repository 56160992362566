import { styled } from "styled-components";
import { Upload, Button } from "antd";
const { Dragger } = Upload;

export const TextButton = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => props.theme.datwylerText.textBrand};
`;

export const StyledDrager = styled(Dragger)`
  .ant-upload-list-item {
    padding: 20px !important;
    &:not(.ant-upload-list-item-error) {
      border: none !important;
    }
    border-radius: 4px !important;
    background-color: ${(props) =>
      props.theme.datwylerSurface.surfaceSecondary} !important;

    .ant-upload-list-item-name {
      font-size: 16px;
      font-weight: 400;
      color: ${(props) => props.theme.datwylerText.textPrimary};
    }
  }
  .ant-upload-list-item-thumbnail {
    align-content: center;
  }

  &.ant-form-item-has-error {
    .ant-upload-drag {
      border: 1px solid red;
    }
  }
  .ant-upload-list-item-thumbnail {
    background-color: ${(props) => props.theme.datwylerSurface.surfaceBrand3};
    border-radius: 100px;
  }
`;
