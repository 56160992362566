import { useContext } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { Breadcrumb, ConfigProvider, Flex } from "antd";
import { SingleArrowRightIcon } from "../../icons";

const BasicBreadcrumbs = (props) => {
  const { items, style, link: Link, state = null } = props;
  const { theme } = useContext<any>(DarkModeContext);
  return (
    <ConfigProvider theme={theme}>
      <Breadcrumb
        separator={
          <Flex
            style={{
              width: "40px",
              justifyContent: "center",
              lineHeight: "16px",
            }}
          >
            <SingleArrowRightIcon />
          </Flex>
        }
        style={{
          fontFamily: "Poppins",
          fontWeight: 600,
          padding: "4px",
          lineHeight: "16px",
          ...style,
        }}
        items={items}
        itemRender={(currentRoute, params, items, paths) => {
          const isLast = currentRoute?.href === items[items.length - 1]?.href;
          return isLast ? (
            <span>{currentRoute.title}</span>
          ) : (
            <Link to={currentRoute.href} state={state}>
              {currentRoute.title}
            </Link>
          );
        }}
      />
    </ConfigProvider>
  );
};

export default BasicBreadcrumbs;
