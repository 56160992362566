import {
  Table,
  ConfigProvider,
  Pagination,
  Flex,
  Typography,
  Select,
} from "antd";
import { useContext } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { useIntl } from "react-intl";
import useScreenOptions from "../../hooks/useScreenOptions";

const { Text } = Typography;

const defaultRowsPerPageOptions = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
];

const textStyle = {
  fontSize: "14px",
  fontWeight: 400,
};

const BasicDataTable = (props) => {
  const {
    data = [{}],
    columns,
    pageNumber = 1,
    rowsPerPage = 10,
    onChangeRowsPerPage,
    onChangePage,
    total = 10,
    rowSelection = false,
    rowKey = () => {},
    onRow = () => {}, // set props for each row, you can add onClick function here
    style = {},
  } = props;
  const { theme } = useContext<any>(DarkModeContext);
  const intl = useIntl();
  const { isMobileScreen, screenSize } = useScreenOptions();

  return (
    <ConfigProvider theme={theme}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        tableLayout={isMobileScreen ? "auto" : "fixed"}
        style={{ width: "100%", overflow: "auto", ...style }}
        onRow={onRow}
        rowSelection={rowSelection}
        rowKey={rowKey}
        // Be careful not to use scroll={{ x: example }}
        // it causes a "ResizeObserver loop completed with undelivered notifications" error
        // https://juejin.cn/post/7262623363700981797
      />
      <Flex
        wrap
        gap="middle"
        style={{
          marginTop: "24px",
          justifyContent: screenSize === "xs" ? "center" : "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text style={textStyle}>
          {intl.formatMessage({ id: "rows_per_page" }) + ":"}
        </Text>
        <Select
          style={{
            marginLeft: "16px",
            marginRight: "16px",
            minWidth: "80px",
          }}
          defaultValue={rowsPerPage}
          onChange={onChangeRowsPerPage}
          options={defaultRowsPerPageOptions}
        />
        <Pagination
          current={pageNumber}
          total={total}
          onChange={onChangePage}
          pageSize={rowsPerPage}
          showSizeChanger={false}
        />
      </Flex>
    </ConfigProvider>
  );
};

export default BasicDataTable;
