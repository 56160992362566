const messages = {
  alarms: "警报",
  alarm_id: "这是我的护照",
  alarm_device: "设备",
  alarm_site: "地点",
  alarm_level: "等级",
  alarm_attribute: "属性",
  alarm_status: "地位",
  alarm_description: "描述",
  alarm_assigned_to: "分配 To",
  alarm_last_updated_by: "最后更新",
  alarm_last_updated_time: "最后更新的时间",
  alarm_active: "积极的",
  alarm_in_progress: "进行中",
  alarm_escalated: "升级",
  alarm_resolved: "解决",
  alarm_deactivated: "停用",
  alarm_date: "日期",
  alarms_sortby: "排序方式",
  alarm_site_placeholder: "选择网站",
  alarm_device_placeholder: "选择设备类型",
  alarm_level_placeholder: "警报级别",
  alarm_user_validation_msg: "需要用户名",
  alarm_select_assignTo: "选择一个人",
  alarm_assign_to: "分配",
  alarm_export: "出口",
  alarm_excel_filename: "警报报告",
  alarm_unassigned: "统一警报",
  alarm_escalation_logs: "升级日志",
  alarm_message: "信息",
  alarm_escalated_by: "升级",
  alarm_escalated_on: "升级",
  alarm_deactivated_msg: "警报停用",
  alarms_deactivated_msg: "警报停用",
  alarm_status_update: "警报状态成功更新。",
  alarm_message_validation_msg: "需要详细信息",
  alarm_message_field: "其他详细信息",
  alarm_escalate_title: "升级问题",
  alarm_deactivate_btn: "停用",
  alarm_escalate_btn: "问题升级",
};

export default messages;
