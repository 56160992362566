import { styled } from "styled-components";

export const ErrorPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 84px);
  position: relative;
  .circle1 {
    position: absolute;
    width: 504px;
    height: 504px;
    left: calc(50% - 504px / 2);
    top: calc(50% - 504px / 2);
    border-radius: 50%;
    background: rgba(70, 187, 203, 0.5);
    opacity: 0.1;
    filter: blur(10.9px);
  }

  .circle2 {
    position: absolute;
    width: 394px;
    height: 394px;
    left: calc(50% - 394px / 2);
    top: calc(50% - 394px / 2);
    border-radius: 50%;
    background: rgba(70, 187, 203, 0.5);
    opacity: 0.1;
  }

  .circle3 {
    position: absolute;
    width: 272px;
    height: 272px;
    left: calc(50% - 272px / 2);
    top: calc(50% - 272px / 2);
    border-radius: 50%;
    background: rgba(70, 187, 203, 0.5);
    opacity: 0.1;
  }

  .circle4 {
    position: absolute;
    width: 178px;
    height: 178px;
    left: calc(50% - 178px / 2);
    top: calc(50% - 178px / 2);
    border-radius: 50%;
    background: rgba(70, 187, 203, 0.5);
    opacity: 0.1;
  }

  .error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

export const ErrorPageFooter = styled.div`
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5a5c5f;
  a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5a5c5f;
  }
`;
