export const ADMIN_PERMISSIONS = [
  "/home",
  "/site",
  "/site/attachments",
  "/device-monitoring",
  "/device-monitoring/deviceDetails",
  "/alarm",
  "/user",
  "/user/profile",
  "/admin",
  "/telemetry",
  "manage_location",
  "manage_site",
  "manage_support_emails",
];

export const TENANT_PERMISSIONS = [
  "/home",
  "/site",
  "/site/attachments",
  "/device-monitoring",
  "/device-monitoring/deviceDetails",
  "/alarm",
  "/user",
  "/user/profile",
  "/telemetry",
  "manage_location",
  "manage_site",
  "manage_support_emails",
];

export const OPERATOR_PERMISSIONS = [
  "/home",
  "/site",
  "/site/attachments",
  "/alarm",
  "/device-monitoring",
  "/device-monitoring/deviceDetails",
  "/telemetry",
  "/user/profile",
];

export const PAGE_WITHOUT_LICENSE = ["/admin", "/user/profile"];
