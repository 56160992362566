import { ConfigProvider, Popover } from "antd";
import { FilterIcon } from "../../icons";
import BasicButton from "../BasicButton";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { DarkModeContext } from "../../providers/DarkModeProvider";

const BasicFilter = (props) => {
  // open and set open must be controlled from parent
  const {
    content,
    open = false,
    setOpen,
    overlayStyle = { width: "350px", maxWidth: "100%" },
    isHideLabel = false,
  } = props;

  const intl = useIntl();
  const { theme } = useContext<any>(DarkModeContext);

  return (
    <ConfigProvider theme={theme}>
      <Popover
        content={content}
        trigger="click"
        open={open}
        overlayStyle={overlayStyle}
        onOpenChange={(open) => {
          setOpen(true);
          // When clicking elsewhere, after selecting role or status,
          // the popover closes before the select dialog
          // this causes an error ResizeObserver limit exceeded.
          // do not allow close when clicking elsewhere
          // only close when clicking on filter or apply BasicButton
        }}
        placement="bottomLeft"
      >
        <BasicButton
          style={{ marginRight: "16px", fontSize: 16 }}
          icon={<FilterIcon width={"24px"} height={"24px"} />}
          onClick={() => setOpen(!open)}
        >
          {isHideLabel ? "" : intl.formatMessage({ id: "filter" })}
        </BasicButton>
      </Popover>
    </ConfigProvider>
  );
};

export default BasicFilter;
