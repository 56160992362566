const messages = {
  // Profile
  my_profile: "我的资料",
  role: "角色",
  company: "公司",
  name: "姓名",
  country: "国家",
  contact_number: "联系电话",
  sign_out: "登出",
  edit_profile: "编辑个人资料",
  change_password: "更改密码",
  profile_info: "档案信息",
  log_out: "登出",
  confirm_logout: "您确定要退出吗?",

  // Users
  add_user: "添加账户",
  update_profile_info: "更新个人资料信息",
  update_user: "更新用户",
  deactivate: "停用",
  reactivate: "重新激活",
  deactivate_user: "停用账户",
  excel_filename_user: "账户报告",
  select_country_code: "请选择国家代码",
  enter_company_name: "输入公司名称",

  // User Table
  first_name: "名字",
  last_name: "姓氏",
  email: "电子邮件",

  // Roles
  ADMIN: "管理员",
  OPERATOR: "操作员",

  // success messages
  user_deactivated_success: "账户已成功停用",
  new_user_added: "新账户添加成功",
  update_user_profile_success: "已成功更新您的信息。",
};

export default messages;
