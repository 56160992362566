export const ACTIVE = "ACTIVE";
export const DEACTIVATED = "DEACTIVATED";
export const DEFAULTNAVBARWIDTH = 280;
export const HEADERHEIGHT = 96;
export const HEADERHEIGHTMOBILE = 64;
export const COLLAPSEDNAVBARWIDTH = 160;

export const ADMIN = "ADMIN";
export const OPERATOR = "OPERATOR";

export const LICENSE_STATUS = {
  ACTIVE: "ACTIVE",
  EXPIRING: "EXPIRING",
  EXPIRED: "EXPIRED",
};
